/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { errorMessages } from '../constants';
import { OrgDashboardService } from '../../dashboard/services/org-dashboard.service';
import { IPortalConfigResponse } from '../../software/software.types';
import * as globalL10n from '../../../global.l10n';

const { ...globalL10nKeys } = globalL10n;

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.less'],
})
export class ErrorPageComponent implements OnInit {
  @Input()
  public errorMessage = history.state?.errorMessage || errorMessages.GENERAL_MESSAGE;;

  public readonly globalL10nKeys = globalL10nKeys;

  public isInfoBannerVisible = false;

  public bannerHTMLText = '';

  constructor(
    private readonly orgDashboardService: OrgDashboardService,
  ) {}

  /** @override */
  public ngOnInit(): void {
    this.getBannerText();
  }

  /**
   * Hide banner on cancel button click.
   */
  public hideInfoBanner(): void {
    this.isInfoBannerVisible = false;
  }

  /**
   * Invoke API to get general information banner text.
   */
  private getBannerText(): void {
    this.orgDashboardService.getBannerText().subscribe(
      (data: IPortalConfigResponse) => {
        const { configs: [{ val }] } = data;

        this.bannerHTMLText = val ?? '';
        this.isInfoBannerVisible = true;
      });
  }
}
