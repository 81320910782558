/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'core';

export const nsxAlbCloudServicesTitle = `${moduleName}.nsxAlbCloudServicesTitle`;
export const openUserProfileLabel = `${moduleName}.openUserProfileLabel`;
export const manageEntitlementsLabel = `${moduleName}.manageEntitlementsLabel`;
export const searchEntitlementsLabel = `${moduleName}.searchEntitlementsLabel`;
export const broadcomSupportPortalLabel = `${moduleName}.broadcomSupportPortalLabel`;
export const portalApiDocumentationLabel = `${moduleName}.portalApiDocumentationLabel`;
export const resourcesLabel = `${moduleName}.resourcesLabel`;
export const toggleUserSettingsMenuLabel = `${moduleName}.toggleUserSettingsMenuLabel`;
export const myAccountLabel = `${moduleName}.myAccountLabel`;
export const signOutLabel = `${moduleName}.signOutLabel`;
export const noEntitlementsDataLabel = `${moduleName}.noEntitlementsDataLabel`;
export const doneLabel = `${moduleName}.doneLabel`;
export const switchingLocationsLabel = `${moduleName}.switchingLocationsLabel`;
export const productLocationLabel = `${moduleName}.productLocationLabel`;
export const productLocationChangeWarning = `${moduleName}.productLocationChangeWarning`;
export const sessionTimeoutWarningLabel = `${moduleName}.sessionTimeoutWarningLabel`;
export const sessionTimeoutExtendWarning = `${moduleName}.sessionTimeoutExtendWarning`;
export const logoutLabel = `${moduleName}.logoutLabel`;
export const userDetailsLabel = `${moduleName}.userDetailsLabel`;
export const firstNameLabel = `${moduleName}.firstNameLabel`;
export const lastNameLabel = `${moduleName}.lastNameLabel`;
export const emailLabel = `${moduleName}.emailLabel`;
export const phoneNoLabel = `${moduleName}.phoneNoLabel`;
export const eaNumberLabel = `${moduleName}.eaNumberLabel`;
export const eaNameLabel = `${moduleName}.eaNameLabel`;
export const siteIdLabel = `${moduleName}.siteIdLabel`;
export const orgDashboardTitle = `${moduleName}.orgDashboardTitle`;
export const softwareTitle = `${moduleName}.softwareTitle`;
export const nsxAlbTitle = `${moduleName}.nsxAlbTitle`;
export const akoTitle = `${moduleName}.akoTitle`;
export const amkoTitle = `${moduleName}.amkoTitle`;
export const crsTitle = `${moduleName}.crsTitle`;
export const additionalToolsTitle = `${moduleName}.additionalToolsTitle`;
export const saasOpsTitle = `${moduleName}.saasOpsTitle`;
export const healthServicesTitle = `${moduleName}.healthServicesTitle`;
export const infrastructureServicesTitle = `${moduleName}.infrastructureServicesTitle`;
export const productLocationTitle = `${moduleName}.productLocationTitle`;
export const dashboardTitle = `${moduleName}.dashboardTitle`;
export const saasTitle = `${moduleName}.saasTitle`;
export const supportCasesTitle = `${moduleName}.supportCasesTitle`;
export const licensingTitle = `${moduleName}.licensingTitle`;
export const inventoryTitle = `&{moduleName}.inventoryTitle`;
export const licensesTitle = `${moduleName}.licensesTitle`;
export const notificationsTitle = `${moduleName}.notificationsTitle`;

export const ENGLISH = {
  [nsxAlbCloudServicesTitle]: 'NSX ALB Cloud Services',
  [openUserProfileLabel]: 'Open user profile',
  [manageEntitlementsLabel]: 'Manage Entitlements',
  [searchEntitlementsLabel]: 'Search Entitlements',
  [broadcomSupportPortalLabel]: 'Broadcom Support Portal',
  [portalApiDocumentationLabel]: 'Portal API Documentation',
  [resourcesLabel]: 'Resources',
  [toggleUserSettingsMenuLabel]: 'Toggle user settings menu',
  [myAccountLabel]: 'My Account',
  [signOutLabel]: 'Sign Out',
  [noEntitlementsDataLabel]: 'No Entitlements Data.',
  [doneLabel]: 'Done',
  [switchingLocationsLabel]: 'Switching Locations',
  [productLocationLabel]: 'Where is your product hosted?',
  [productLocationChangeWarning]: 'Switching locations will log you out of NSX ALB Cloud Services and require you to log back in.',
  [sessionTimeoutWarningLabel]: 'Session Timeout Warning',
  [sessionTimeoutExtendWarning]: 'Your session will expire in ({0}). Do you want to extend the session?',
  [logoutLabel]: 'Logout',
  [userDetailsLabel]: 'Users Details',
  [firstNameLabel]: 'First Name',
  [lastNameLabel]: 'Last Name',
  [emailLabel]: 'Email',
  [phoneNoLabel]: 'Phone No',
  [eaNumberLabel]: 'EA Number',
  [eaNameLabel]: 'EA Name',
  [siteIdLabel]: 'Site ID',
  [orgDashboardTitle]: 'Org Dashboard',
  [softwareTitle]: 'Software',
  [nsxAlbTitle]: 'NSX ALB',
  [akoTitle]: 'AKO',
  [amkoTitle]: 'AMKO',
  [crsTitle]: 'CRS',
  [additionalToolsTitle]: 'Additional Tools',
  [saasOpsTitle]: 'SaaS Ops',
  [healthServicesTitle]: 'Health Services',
  [infrastructureServicesTitle]: 'Infrastructure Services',
  [productLocationTitle]: 'Product Location',
  [dashboardTitle]: 'Dashboard',
  [saasTitle]: 'SaaS',
  [supportCasesTitle]: 'Support Cases',
  [licensingTitle]: 'Licensing',
  [inventoryTitle]: 'Inventory',
  [licensesTitle]: 'Licenses',
  [notificationsTitle]: 'Notifications',
};
