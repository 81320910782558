/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import {
  catchError,
  map,
  Observable,
  throwError,
} from 'rxjs';

import { errorMessages } from '../../shared/constants';
import { ROUTER_LINKS } from '../router-links.constants';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiHandlerService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly location: Location,
  ) {}

  private static saveMyChunck(count: string): void {
    let newCount = parseInt(count, 10);

    newCount = newCount + 1;
    sessionStorage.setItem('myCount', newCount.toString());

    const storageTotalChuncks = sessionStorage.getItem('totalChuncks');
    let totalChuncks: any;
    let myCount: any;

    if (storageTotalChuncks !== null) {
      totalChuncks = parseInt(storageTotalChuncks, 10);
    }

    const storageMyCount = sessionStorage.getItem('myCount');

    if (storageMyCount !== null) {
      myCount = parseInt(storageMyCount, 10);
    }

    const progressBarVal: any = Math.round((myCount / totalChuncks) * 98);

    sessionStorage.setItem('progressVal', progressBarVal.toString());
  }

  /**
   * Handles api response
   * @param any response
   */
  private static handleResponse(response: any): any {
    if (response.status === 204) {
      return {};
    }

    return response.body;
  }

  /**
   * Handles AWS response
   * @param any response
   */
  private static handleAWSResponse(response: any): any {
    const currentCount = sessionStorage.getItem('myCount');

    ApiHandlerService.saveMyChunck(currentCount || '0'); // '0' if  value is null

    if (response.status === 204) {
      return {};
    }

    return response;
  }

  public getPublicURL(url: string): Observable<any> {
    return this.httpClient
      .get(url, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => res.body),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * Executes GET api
   */
  public get(url: string): Observable<any> {
    return this.httpClient
      .get(url, { observe: 'response', withCredentials: true })
      .pipe(
        map((res: HttpResponse<any>) => ApiHandlerService.handleResponse(res)),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * @param string url - Execites POST api
   * @param any data
   */
  public save(url: string, data: any): Observable<any> {
    return this.httpClient
      .post(url, data, { observe: 'response', withCredentials: true })
      .pipe(
        map((res: HttpResponse<any>) => ApiHandlerService.handleResponse(res)),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * @param string url - Executes POST api
   * @param any data
   */
  public saveWithAttachments(url: string, data: any): Observable<any> {
    const formData = new FormData();

    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
    }

    return this.httpClient
      .post(url, formData, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => ApiHandlerService.handleResponse(res)),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * @param string url - Executes POST api
   * @param any data
   */
  public updateWithAttachments(url: string, data: any): Observable<any> {
    const formData = new FormData();

    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
    }

    return this.httpClient
      .put(url, formData, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => ApiHandlerService.handleResponse(res)),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * @param string url - Executes PUT api
   * @param any data
   */
  public update(url: string, data: any): Observable<any> {
    return this.httpClient
      .put(url, data, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => ApiHandlerService.handleResponse(res)),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * Perform update using PATCH method
   * @param url - endpoint used
   * @param data - payload data
   */
  public patchUpdate(url: string, data: object): Observable<any> {
    return this.httpClient
      .patch(url, data, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => ApiHandlerService.handleResponse(res)),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * @param string url - Executes DELETE api
   */
  public delete(url: string): Observable<any> {
    return this.httpClient
      .delete(url, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => ApiHandlerService.handleResponse(res)),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
      );
  }

  /**
   * Handles api error
   * @param HttpErrorResponse error
   */
  private handleError(error: HttpErrorResponse): any {
    this.logError(error);

    if (error.status === 401) {
      const currentPath = this.location.path();
      const {
        authMode,
        sessionRetryCount,
        maxSessionRetryCount,
      } = this.localStorageService;

      this.localStorageService.reset();
      this.localStorageService.authMode = authMode;

      /**
       * When API gets failed with 401 (un-authorized error) UI default behaviour is to consider
       * it as session expired. Which will again try for login and get stuck in an infinite loop.
       *
       * To break the loop we have implemented max retry attempts from UI end,
       * once it reaches to the limit, we will navigate user to the hard-stop error-page.
       *
       * The current session retry limit is of 3 times and same has been confirmed with Pulse-Team.
       */
      if (sessionRetryCount < maxSessionRetryCount) {
        this.router.navigate(
          [ROUTER_LINKS.LOGIN],
          { state: { redirectUrl: currentPath } },
        );
      } else {
        this.router.navigate(
          [ROUTER_LINKS.ERROR_PAGE],
          { state: { redirectUrl: currentPath, errorMessage: error.message } },
        );
      }
    }

    return throwError(error.error || errorMessages.GENERAL_MESSAGE);
  }

  /**
   * Log error to browser's console for all Error scenarios.
   */
  // eslint-disable-next-line class-methods-use-this
  private logError(error: HttpErrorResponse): void {
    console.error('Error from API handler service:', error.message, error);
  }
}
