/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *  Module containing all core components like - Header, Login, UserDetails, VerticalNavbar etc.
 *  This module will act as a sidecar for acp.module which is the root module of the application.
 */
/** @module CoreModule */

import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import { VIPModule } from '@vmw/ngx-vip';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Modules
import { SharedModule } from '../shared/shared.module';
import { ClarityImportsModule } from '../../clarity-imports.module';

// Services
import { LoginService } from '../shared/services/login.service';

// Components
import { AcpHeaderComponent } from './components/acp-header/acp-header.component';
import { LoginComponent } from './components/login/login.component';
import { ManageAccountsComponent } from './components/manage-accounts/manage-accounts.component';
import { ProductLocationComponent } from './components/product-location/product-location.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { VerticalNavComponent } from './components/vertical-nav/vertical-nav.component';
import { SessionTimeoutModalComponent } from './components/session-timeout/session-timeout-modal.component';

@NgModule({
  declarations: [
    AcpHeaderComponent,
    LoginComponent,
    ManageAccountsComponent,
    ProductLocationComponent,
    SessionTimeoutModalComponent,
    UserDetailsComponent,
    VerticalNavComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ClarityImportsModule,
    RouterModule,
    SharedModule,
    VIPModule.forChild(),
  ],
  exports: [
    AcpHeaderComponent,
    LoginComponent,
    ManageAccountsComponent,
    ProductLocationComponent,
    SessionTimeoutModalComponent,
    UserDetailsComponent,
    VerticalNavComponent,
  ],
  providers: [
    LoginService,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ],
})
export class CoreModule { }
