/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import { CspApiService } from '@vmw/csp-ngx-components';
import { OAuth2ModuleConfiguration } from '@vmw/ngx-csp-oauth2';
import { environment } from '../environments/environment';
import { CSP_ENVIRONMENTS } from './modules/shared/constants';
import { ROUTER_LINKS } from './modules/shared/router-links.constants';

/**
 * OAuth2Module Configuration.
 */
export const getOAuthConfig = (): OAuth2ModuleConfiguration => ({
  cspHostname: '',
  clientId: environment.client_id,
  // The SPA's *LOCAL* redirect URI to which the OAuth2 response should be returned.
  redirectUri: `${location.origin}${ROUTER_LINKS.DASHBOARD}/`,
  resourceServers: [],
  // An additional buffer time, in seconds,
  // at which the current token should be considered expired.
  tokenExpirationBuffer: 60,
  storage: 'local',
});

/**
 * OAuthConfigFactory to set CSP environment and CSP hostname.
 */
export const portalOauth2ConfigFactory =
  (cspApiService: CspApiService): OAuth2ModuleConfiguration => {
    const cspEnvironment = CSP_ENVIRONMENTS[environment.deploymentEnv];

    cspApiService.setCspEnvironment(cspEnvironment);

    const cspHost = cspApiService.getCspHost();
    const config = getOAuthConfig();

    return Object.assign(config, { cspHostname: `${cspHost}`});
  };
