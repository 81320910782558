<div class="modal">
  <div
    class="modal-dialog"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-content">
      <div class="modal-header">
        <cds-icon-button
          action="flat"
          class="close"
          type="basic"
          (click)="cancel()"
        >
          <cds-icon
            aria-hidden="true"
            shape="close"
          ></cds-icon>
        </cds-icon-button>
        <h3 class="modal-title">{{ l10nKeys.manageEntitlementsLabel | vtranslate }}</h3>
      </div>
      <div
        class="modal-body clr-row manage-accounts"
        *ngIf="isUserWithEntitlements()"
      >
        <div class="clr-col-sm-12">
          <clr-datagrid>
            <clr-dg-action-bar class="data-grid__action-bar-grid-container">
              <div class="clr-col-sm-6">
                <avi-search
                  placeholder="{{ l10nKeys.searchEntitlementsLabel | vtranslate }}"
                  (onSearch)="searchAccounts($event)"
                  (onClearSearch)="handleClearSearch()"
                  (keyup)="searchAccounts($event.target.value)"
                ></avi-search>
              </div>
            </clr-dg-action-bar>
      
            <clr-dg-row
              *clrDgItems="let account of filteredAccounts; let i = index" 
              [clrDgItem]="account"
            >
              <clr-dg-cell class="clr-col-sm-10">
                {{ account.eaName }}
              </clr-dg-cell>
              <clr-dg-cell class="manage-accounts__actions-cell clr-col-sm-2">
                <clr-checkbox-container>
                  <clr-checkbox-wrapper>
                    <input
                      type="checkbox"
                      clrCheckbox
                      name="{{ 'select-account-' + i }}"
                      id="{{ 'select-account-' + i }}"
                      [checked]="selectedAccount.eaNumber === account.eaNumber"
                      (change)="setAccount(account)"
                    />
                  </clr-checkbox-wrapper>
                </clr-checkbox-container>
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-placeholder>
              {{ globalL10nKeys.couldntFindAnyObjectsPlaceholder | vtranslate }}
            </clr-dg-placeholder>
          </clr-datagrid>
        </div>
      </div>
      <div class="modal-footer clr-row">
        <cds-button
          class="sel-change-department-done"
          type="primary"
          (click)="changeAccount()"
        >
          {{ l10nKeys.doneLabel | vtranslate }}
        </cds-button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal-backdrop"
  aria-hidden="true"
></div>
