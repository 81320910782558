/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';

export const agreeToLicenseAgreementLabel = `${moduleName}.agreeToLicenseAgreementLabel`;
export const continueQuestionWarning = `${moduleName}.continueQuestionWarning`;
export const availableCountLabel = `${moduleName}.availableCountLabel`;
export const availableServiceUnitsLabel = `${moduleName}.availableServiceUnitsLabel`;
export const licenseTitle = `${moduleName}.licenseTitle`;
export const licenseAgreementTitle = `${moduleName}.licenseAgreementTitle`;
export const noLicenseDataLabel = `${moduleName}.noLicenseDataLabel`;
export const perControllerMaxAllowedLabel = `${moduleName}.perControllerMaxAllowedLabel`;
export const serviceUnitsUsageLabel = `${moduleName}.serviceUnitsUsageLabel`;
export const unlimitedLabel = `${moduleName}.unlimitedLabel`;
export const urlCopiedMessge = `${moduleName}.urlCopiedMessge`;
export const usedCountLabel = `${moduleName}.usedCountLabel`;
export const viewAllLabel = `${moduleName}.viewAllLabel`;
export const yesContinueLabel = `${moduleName}.yesContinueLabel`;
export const lastWeekLabel = `${moduleName}.lastWeekLabel`;
export const lastMonthLabel = `${moduleName}.lastMonthLabel`;
export const lastYearLabel = `${moduleName}.lastYearLabel`;

export const ENGLISH = {
  [agreeToLicenseAgreementLabel]: 'I AGREE TO THIS LICENSE AGREEMENT',
  [continueQuestionWarning]: 'Are you sure you want to continue?',
  [availableCountLabel]: 'Available - {0}',
  [availableServiceUnitsLabel]: 'Available Service Units',
  [licenseTitle]: 'LICENSE',
  [licenseAgreementTitle]: 'LICENSE AGREEMENT',
  [noLicenseDataLabel]: 'No license data.',
  [perControllerMaxAllowedLabel]: 'Per-controller Max Allowed',
  [serviceUnitsUsageLabel]: ' Service Units Usage',
  [unlimitedLabel]: 'Unlimited',
  [urlCopiedMessge]: 'URL copied to clipboard, this URL will expire in 6 hours.',
  [usedCountLabel]: 'Used - {0}',
  [viewAllLabel]: 'VIEW ALL',
  [yesContinueLabel]: 'Yes, Continue',
  [lastWeekLabel]: 'Last Week',
  [lastMonthLabel]: 'Last Month',
  [lastYearLabel]: 'Last Year',
};
