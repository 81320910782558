/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CoreModule */

import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from '../../../shared/services/login.service';
import { AUTH_MODE } from '../../../shared/constants';
import { LocalStorageService } from '../../../shared/services/local-storage.service';

@Component({
  selector: 'login',
  template: '',
})
export class LoginComponent implements OnInit, OnDestroy {
  private routeParamSubscription: Subscription = new Subscription();

  constructor(
    private readonly loginService: LoginService,
    private readonly localStorageService: LocalStorageService,
    private readonly route: ActivatedRoute,
  ) {
    if (!this.localStorageService.authMode) {
      this.localStorageService.authMode = AUTH_MODE.CSP;
    }
  }

  public ngOnInit(): void {
    this.routeParamSubscription = this.route.queryParams.subscribe( qp => {
      if (qp['authmode']) {
        this.localStorageService.authMode = qp['authmode'];
      }
    });

    this.loginService.login();
  }

  public ngOnDestroy(): void {
    this.routeParamSubscription.unsubscribe();
  }
}
