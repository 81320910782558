/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import {
  OAuth2Client,
  OAuth2TokenResponse,
} from '@vmw/csp-oauth2';

import { OAuth2TokenSubject } from '@vmw/ngx-csp-oauth2';
import { AUTH_MODE } from '../constants';
import { LocalStorageService } from './local-storage.service';

/**
 * @description AuthService to handle CSP related authentication.
 *
 * @author Rajawant Prajapati
 */

@Injectable()
export class AuthService {
  /**
   * Used to check if user is authenticated in CSP mode.
   */
  private isAuthorized = false;

  /**
   * Holds login redirect path returned from oAuthClient.
   */
  private loginRedirectPath = '';

  /**
   * Holds access token related information.
   */
  private readonly accessTokenSubject: BehaviorSubject<string | null> =
  new BehaviorSubject<string | null>(null);

  constructor(
    private readonly oauth2Client: OAuth2Client,
    private readonly oauthToken: OAuth2TokenSubject,
    private readonly location: Location,
    private readonly localStorageService: LocalStorageService,
  ) {}

  /**
   * Returns true, if user is logged in.
   * Handles authorization response and set authorization status.
   */
  public async login(targetOrgLink?: string): Promise<boolean> {
    let success = false;

    // Check if there is an existing token for the session
    const token: OAuth2TokenResponse = this.oauth2Client.token();
    const hasAuthorizationResponse = this.oauth2Client.hasAuthorizationResponse();

    if (token?.access_token) {
      await this.handleExistingToken(targetOrgLink);
      success = true;
    } else if (targetOrgLink && !hasAuthorizationResponse) {
      // Check if there is an existing session
      // Check if user is redirected from csp tile with valid orgLink
      try {
        this.doLogin(targetOrgLink);
        success = true;
      } catch (e) {
        // The token string is malformed and should no longer be used
        this.oauth2Client.clear();
        success = false;
      }
    } else if (hasAuthorizationResponse) {
      // There is an authorization response from CSP.
      // The app falls into this scenario after a successful call to oauth2Client.authorize
      success = await this.handleCspAuthorizationResponse();
    }

    if (success) {
      const {access_token: accessToken} = this.oauth2Client.token();

      this.accessTokenSubject.next(accessToken);
      this.isAuthorized = true;
    }

    return success;
  }

  /**
   * Return the value of the auth token independent on whether login has completed and succeeded,
   * thus the result value may be null.
   */
  public getToken(): string | null {
    return this.accessTokenSubject.getValue();
  }

  /**
   * Returns the eaNumber, also referred to as the OrgId, for the selected entitlement.
   */
  public getOrgId(): string {
    return this.localStorageService.eaNumber ?? '';
  }

  /**
   * Returns login redirect path.
   */
  public getLoginRedirectPathFromState(): string {
    return this.loginRedirectPath;
  }

  /**
   * Returns true, if user is authorized.
   */
  public getIsAuthorized(): boolean {
    return this.isAuthorized;
  }

  /**
   * Return true for Restricted Mode if the conditions are met,
   * - In CSP Mode: If the user is logged in, has no entitlements, and the org is set to "default".
   * - In VMW Mode: If the user is logged in and has no entitlements.
   *
   * In Restricted Mode UI should only show the Softwares tab,
   * that too with CRS option hidden to user.
   */
  public isRestrictedMode(): boolean {
    const { loggedIn, authMode, userWithEntitlements } = this.localStorageService;

    if (!loggedIn) {
      return false;
    }

    return (authMode === AUTH_MODE.VMW || this.getOrgId() === 'default') && !userWithEntitlements;
  }

  /**
   * Calls authorize api with orgLink.
   */
  private doLogin(orgLink?: string): void {
    // The authorize method redirects to CSP
    this.oauth2Client.authorize({ lastUrl: this.location.path() }, orgLink);
  }

  /**
   * Sets login redirect path.
   */
  private setLoginRedirectPathFromState(loginRedirectPath: string): void {
    this.loginRedirectPath = loginRedirectPath;
  }

  /**
   * Handles existing token.
   */
  private async handleExistingToken(orgLink?: string): Promise<void> {
    // handle existing token, this call will not redirect to the CSP portal
    await this.oauthToken
      .autoLogin(window.location.href, orgLink)
      .pipe(first()).toPromise();
  }

  /**
   * Handles csp authorization response.
   */
  private async handleCspAuthorizationResponse(): Promise<boolean> {
    try {
      // If the response is valid the new access token will be available to OAuth2Client
      const state = await this.oauth2Client.validateAuthorizeResponse();

      this.setLoginRedirectPathFromState(state.loginRedirectPath);
    } catch (e) {
      return false;
    }

    return true;
  }
}
