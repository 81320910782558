import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AcpModule } from './app/acp.module';
import { environment } from './environments/environment';

import '@cds/core/select/register.js';
import '@cds/core/search/register.js';
import '@cds/core/button/register.js';
import '@cds/core/alert/register.js';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AcpModule)
  // tslint:disable-next-line: no-console
  .catch(err => console.log(err));
