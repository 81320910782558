<div class="acp-profile-card">
  <div class="acp-profile-card__head">
    <div class="acp-profile-card__logo">
      <cds-icon
        shape="vm-bug"
        size="lg"
      ></cds-icon>
    </div>
    <div>
      <div>
        <strong>
          {{ profile.userInformation.firstName }} {{ profile.userInformation.lastName }}
        </strong>
      </div>
      <div
        class="acp-profile-card__email"
        [title]="profile.emailAddress"
      >
        {{ profile.emailAddress }}
      </div>
    </div>
  </div>
  <cds-button
    class="acp-profile-card__account sel-my-profile"
    (click)="onShowProfile()"
  >
    {{ l10nKeys.myAccountLabel | vtranslate }}
  </cds-button>
  <div
    class="acp-profile-card__logout"
    (click)="onSignOut()"
  >
    {{ l10nKeys.signOutLabel | vtranslate }}
  </div>
</div>
