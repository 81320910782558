/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { IAppState } from '../../shared/models/app-state.model';
import { IMyProfile } from '../../shared/models/myprofile.model';

const ACP_APP_STATE = 'acp-app-state';
const SESSION_RETRY_COUNT = 'session-retry-count';

/**
 * @name LocalStorageService
 * @module AcpModule
 * @desc Service deals with local storage interaction. Service has various
 *  functions to get  and set properties associated with the application.
 * @author Ram Pal
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Maximum allowed attempts to get session details from API.
   */
  public readonly maxSessionRetryCount = 3;

  private state: IAppState = {} as IAppState;

  /**
   * Sets authMode associated with current entitlement.
   */
  public set authMode(value: string) {
    this.state.authMode = value;
    this.exportStateToLocalStorage();
  }

  /**
   * Returns authMode associated with current entitlement.
   */
  public get authMode(): string {
    this.loadFromLocalStorage();

    return this.state.authMode;
  }

  /**
   * Sets login status of the current user.
   */
  public set loggedIn(value: boolean) {
    this.state.loggedIn = value;
    this.exportStateToLocalStorage();
  }

  /**
   * Returns login status of the current user.
   */
  public get loggedIn(): boolean {
    this.loadFromLocalStorage();

    return this.state.loggedIn;
  }

  /**
   * Sets current user's role as admin.
   */
  public set isSuperUser(value: boolean) {
    this.state.isSuperUser = value;
    this.exportStateToLocalStorage();
  }

  /**
   * Function returns true if current user's role is admin.
   */
  public get isSuperUser(): boolean {
    this.loadFromLocalStorage();

    return this.state.isSuperUser;
  }

  /**
   * Sets eaNumber associated with current entitlement.
   */
  public set eaNumber(value: string) {
    this.state.eaNumber = value;
    this.exportStateToLocalStorage();
  }

  /**
   * Returns eaNumber associated with current entitlement.
   */
  public get eaNumber(): string {
    this.loadFromLocalStorage();

    return this.state.eaNumber;
  }

  /**
   * Sets eaName associated with current entitlement.
   */
  public set eaName(value: string) {
    this.state.eaName = value;
    this.exportStateToLocalStorage();
  }

  /**
   * Returns eaName associated with current entitlement.
   */
  public get eaName(): string {
    this.loadFromLocalStorage();

    return this.state.eaName;
  }

  /**
   * Sets flag if user has entitlement or not.
   */
  public set userWithEntitlements(value: boolean) {
    this.state.userWithEntitlements = value;
    this.exportStateToLocalStorage();
  }

  /**
   * Returns true if user has entitlement.
   */
  public get userWithEntitlements(): boolean {
    this.loadFromLocalStorage();

    return this.state.userWithEntitlements;
  }

  /**
   * Set user profile to local storage.
   */
  public set userProfile(userProfile: IMyProfile) {
    this.state.userProfile = userProfile;

    this.exportStateToLocalStorage();
  }

  /**
   * Getter for user profile.
   */
  public get userProfile(): IMyProfile {
    this.loadFromLocalStorage();

    const { userProfile } = this.state;

    return userProfile;
  }

  /**
   * Sets session retry count for the user.
   */
  // eslint-disable-next-line class-methods-use-this
  public set sessionRetryCount(value: number) {
    localStorage.setItem(SESSION_RETRY_COUNT, JSON.stringify(value));
  }

  /**
   * Returns session retry count for the user.
   */
  // eslint-disable-next-line class-methods-use-this
  public get sessionRetryCount(): number {
    return Number(localStorage.getItem(SESSION_RETRY_COUNT));
  }

  /**
   * Sets local state and local storage state as empty.
   */
  public reset(): void {
    this.state = {} as IAppState;
    localStorage.removeItem(ACP_APP_STATE);
  }

  /**
   * Function to save local state to local storage.
   */
  private exportStateToLocalStorage(): void {
    localStorage.setItem(ACP_APP_STATE, JSON.stringify(this.state));
  }

  /**
   * This function checks local state of the application and if local state
   * is empty then it pulls state from local storage and sets local state.
   */
  private loadFromLocalStorage(): void {
    if (isEmpty(this.state)) {
      const session = localStorage.getItem(ACP_APP_STATE);

      if (session) {
        this.state = JSON.parse(session) as IAppState;
      }
    }
  }
}
