/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Injectable } from '@angular/core';
import { camelCase, snakeCase } from 'lodash';
import { IHashObj } from '../../shared/models/hash-obj.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  /**
   * Converts object property names from camelcase to snakecase notation,
   * and it works for one level deep object only.
   * @param hash - object with came case property names
   */
  public static convertObjPropNamesToSnakeCase(hash: IHashObj): IHashObj {
    const snakeCaseObject: IHashObj = {};

    Object.keys(hash).forEach(key => {
      snakeCaseObject[snakeCase(key)] = hash[key];
    });

    return snakeCaseObject;
  }

  /**
   * Converts object property names from snakecase to camelcase notation,
   * and it works for one level deep object only.
   * @param hash - object with snake case property names
   */
  public static convertObjPropNamesToCamelCase(hash: IHashObj): IHashObj {
    const camelCaseObject: IHashObj = {};

    Object.keys(hash).forEach(key => {
      camelCaseObject[camelCase(key)] = hash[key];
    });

    return camelCaseObject;
  }
}
