<div class="modal">
  <div
    class="modal-dialog modal-lg"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-content">
      <div class="modal-header">
        <cds-icon-button
          action="flat"
          class="close sel-user-cancel-detail-window"
          type="basic"
          (click)="cancel()"
        >
          <cds-icon
            aria-hidden="true"
            shape="close"
          ></cds-icon>
        </cds-icon-button>
        <h3 class="modal-title sel-user-details-text">{{ l10nKeys.userDetailsLabel | vtranslate }}</h3>
      </div>
      <div class="modal-body clr-row">
        <div class="clr-col-sm-6">
          <strong>{{ l10nKeys.firstNameLabel | vtranslate }}:</strong>
          <span>
            {{ userData.userInformation.firstName || '-' }}
          </span>
        </div>
        <div class="clr-col-sm-6">
          <strong>{{ l10nKeys.lastNameLabel | vtranslate }}:</strong>
          <span>
            {{ userData.userInformation.lastName || '_' }}
          </span>
        </div>
        <div class="clr-col-sm-6">
          <strong>{{ l10nKeys.emailLabel | vtranslate }}:</strong>
          <span>
            {{ userData.emailAddress || '_' }}
          </span>
        </div>
        <div class="clr-col-sm-6">
          <strong>{{ l10nKeys.phoneNoLabel | vtranslate }}:</strong>
          <span>
            {{ userData.userInformation.cellPhone || '_' }}
          </span>
        </div>
        <div class="clr-col-sm-6">
          <strong>{{ l10nKeys.eaNumberLabel | vtranslate }}:</strong>
          <span>
            {{ currentEaDetails?.eaNumber || '_' }}
          </span>
        </div>
        <div class="clr-col-sm-6">
          <strong>{{ l10nKeys.siteIdLabel | vtranslate }}:</strong>
          <span>
            {{ currentEaDetails?.siteId || '_' }}
          </span>
        </div>
        <div class="clr-col-sm-6">
          <strong>{{ l10nKeys.eaNameLabel | vtranslate }}:</strong>
          <span>
            {{ currentEaDetails?.eaName || '_' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal-backdrop"
  aria-hidden="true"
></div>
