/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  private readonly isLoading = new Subject<boolean>();

  /**
   * Show loader when request is hitted and waiting for response.
   */
  public showLoader(): void {
    this.isLoading.next(true);
  }

  /**
   * Hide loader when recives the response from server.
   */
  public hideLoader(): void {
    this.isLoading.next(false);
  }

  /**
   * Returns an observable to allow subscriptions to tenant context changes. The observer will
   * receive the tenantRef on changes.
   */
  public get isLoading$(): Observable<boolean> {
    return this.isLoading.asObservable();
  }
}
