/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ClarityImportsModule */

import { NgModule } from '@angular/core';

import {
  ClarityModule,
  ClrCheckboxModule,
  ClrDatepickerModule,
  ClrDropdownModule,
  ClrFormsModule,
  ClrIconModule,
  ClrInputModule,
  ClrLayoutModule,
  ClrNavigationModule,
  ClrSelectModule,
  ClrVerticalNavModule,
} from '@clr/angular';

@NgModule({
  imports: [
    ClarityModule,
    ClrCheckboxModule,
    ClrDatepickerModule,
    ClrDropdownModule,
    ClrFormsModule,
    ClrIconModule,
    ClrInputModule,
    ClrLayoutModule,
    ClrNavigationModule,
    ClrSelectModule,
    ClrVerticalNavModule,
  ],
  exports: [
    ClarityModule,
    ClrCheckboxModule,
    ClrDatepickerModule,
    ClrDropdownModule,
    ClrFormsModule,
    ClrIconModule,
    ClrInputModule,
    ClrLayoutModule,
    ClrNavigationModule,
    ClrSelectModule,
    ClrVerticalNavModule,
  ],
})
export class ClarityImportsModule {}
