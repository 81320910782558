/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(private readonly router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next({});
        }
      }
    });
  }

  /**
   * Shows the success message
   * @param string message
   * @param boolean keepAfterNavigationChange
   */
  public success(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message });
  }

  /**
   * Shows the error message
   * @param string message
   * @param boolean keepAfterNavigationChange
   */
  public error(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'danger', text: message });
  }

  /**
   * Shows warning message
   * @param message - Message to show in warning popup
   * @param keepAfterNavigationChange - Flag to show warning popup after route change
   */
  public showWarning(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: 'warning',
      text: message,
    });
  }

  /**
   * Get the messgage
   */
  public getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
