/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

/**
 * @fileoverview File containing application level constants.
 * @author Ram Pal, Rajawant Prajapati
 */

import { CspEnvironment } from '@vmw/csp-ngx-components';
import * as globall10n from '../../global.l10n';
import * as corel10n from '../core/core.module.l10n';
import * as sharedl10n from './shared.module.l10n';

const { ...globall10nKeys } = globall10n;
const { ...corel10nKeys } = corel10n;
const { ...sharedl10nKeys } = sharedl10n;

/**
 * Constants containing list of page titles.
 */
export const pageTitles = {
  ADDITIONAL_TOOLS: corel10nKeys.additionalToolsTitle,
  AKO: corel10nKeys.akoTitle,
  AMKO: corel10nKeys.amkoTitle,
  CRS: corel10nKeys.crsTitle,
  DASHBOARD: corel10nKeys.dashboardTitle,
  SOFTWARE: corel10nKeys.softwareTitle,
  LICENSES: corel10nKeys.licensesTitle,
  SAAS: corel10nKeys.saasTitle,
  CONTROLLERS: globall10nKeys.controllersLabel,
  SUPPORT_CASES: corel10nKeys.supportCasesTitle,
  LICENSING: corel10nKeys.licensingTitle,
  PRODUCT_LOCATION: corel10nKeys.productLocationTitle,
  NOTIFICATIONS: corel10nKeys.notificationsTitle,
};

export const SEVERITY_LEVEL_DEFINITIONS_DOC =
  'https://avinetworks.com/docs/18.2/support-terms-and-conditions/';

export const errorMessages = {
  GENERAL_MESSAGE: 'Something went wrong. Please contact support.',
  ACCESS_DENIED: 'Access Denied',
  INVALID_SESSION: 'Session or ID token missing. Redirect to login.',
  INTERNAL_ERROR: 'Internal error, please try again.',
};

/**
 * Idle timeout in seconds.
 * Timeout for user to refresh session or logout of application after idle timeout is complete.
 */
export const USER_SESSION = {
  IDLE_TIMEOUT: 1500,
  TIMEOUT: 180,
};

/**
 * MyVmware support link.
 * Redirects user to myvmware to access case related stuff.
 */
export const MY_VMWARE_PORTAL_URL = 'https://my.vmware.com/';

/**
 * Different authmodes for portal login.
 */
export const enum AUTH_MODE {
  VMW = 'vmw',
  VMWCC = 'vmwcc',
  CSP = 'csp',
  BCOM = 'BROADCOM'
}

/**
 * KB article URL for deprecated or previous versions.
 */
export const KB_ARTICLE_FOR_DEPRECATED_VERSION_URL = 'https://kb.vmware.com/s/article/82049';

/**
 * KB article URL for preferred release versions.
 */
export const KB_ARTICLE_FOR_PREFERRED_VERSION_URL = 'https://kb.vmware.com/s/article/81290';

/**
 * Broadcom Support Portal url.
 */
export const BROADCOM_SUPPORT_PORTAL_URL = 'https://support.broadcom.com/';

/**
 * Resources url.
 */
export const RESOURCES_URL = 'https://avinetworks.com/resources/';

/**
 * CSP Authentication related constants.
 */
export const AUTH_CONSTANTS = {
  CSP_ORG_LINK: '/csp/gateway/am/api/orgs/',
  ORG_ID: 'orgId',
};

/**
 * Constants containing all the CSP Header option.
 */
export const CSP_HEADER_CONFIG = {
  theme: 'Light',
  baseRoute: '/',
  docsProducts: [
    'VMware Cloud Services',
  ],
  docsDefaultSearch: 'VMware Cloud Services',
  title: 'NSX ALB Cloud Services',
  subTitle: 'VMware Cloud Services',
  appTitleAriaLabel: 'NSX ALB Cloud Services home page',
};

/**
 * Mapping of csp environment with production, staging and testing environment.
 */
export const CSP_ENVIRONMENTS = {
  prod: CspEnvironment.PRODUCTION,
  stage: CspEnvironment.STAGING,
  test: CspEnvironment.STAGING,
};

export const EMPTY_VALUE = '-';

export const EMPTY_PLACEHOLDER = '';

/**
 * Page size options for clarity data grid pagination.
 */
export const PAGE_SIZE_OPTIONS = [10, 20, 30, 50];

export const NSX_ALB_TITLE = 'NSX ALB';

/**
 * Support cases duration filter options,
 * with the filter values supported by the backend.
 */
export const CASES_DURATION_OPTIONS = [
  {
    displayLabel: sharedl10nKeys.lastWeekLabel,
    value: 7,
  },
  {
    displayLabel: sharedl10nKeys.lastMonthLabel,
    value: 31,
  },
  {
    displayLabel: sharedl10nKeys.lastYearLabel,
    value: 366,
  },
];

/**
 * Default value for support cases duration filter options. Currently set to last 31 days.
 */
export const DEFAULT_CASES_DURATION_VALUE = CASES_DURATION_OPTIONS[1].value;

/**
 * E015-027 - To indentify authentication error related to customerconnect account.
 */
export const CUSTOMER_CONNECT_LOGIN_ERROR_CODE = 'E015-027';

/**
 * Regex pattern for validating license keys.
 */
export const LICENSE_REGEX = /^[a-zA-Z\d]{5}(?:-[a-zA-Z\d]{5}){4}$/;

/**
 * Default date format for grids.
 */
export const LICENSE_DATE_FORMAT = 'MMM d, y';
