/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *  Common shared module containing shared components, pipes & services which can be used across
 *  different fearture modules in the application.
 */
/** @module SharedModule */

import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

// Modules
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { VIPModule } from '@vmw/ngx-vip';
import { ClarityImportsModule } from '../../clarity-imports.module';

// Pipes
import { SearchFilterPipe } from './pipes/search-items.pipe';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { FilterItemsPipe } from './pipes/filter-items.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { RemoveUnderscorePipe } from './pipes/removeUnderscore.pipe';

// Components
import { AcpAlertComponent } from './components/acp-alert/acp-alert.component';
import { AcpPageLoaderComponent } from './components/acp-page-loader/acp-page-loader.component';
import { AcpEmptyListComponent } from './components/acp-empty-list/acp-empty-list.component';
import { AcpLoaderComponent } from './components/acp-loader/acp-loader.component';
import { AcpPageTitleComponent } from './components/acp-page-title/acp-page-title.component';
import { AcpProfileCardComponent } from './components/acp-profile-card/acp-profile-card.component';
import { AviCardComponent } from './components/avi-card/avi-card.component';
import { AviSearchComponent } from './components/avi-search/avi-search.component';
import { AcpConfirmationModalComponent } from './components/acp-confirmation-modal/acp-confirmation-modal.component';
import { LicenseUsageCardComponent } from './components/license-usage-card/license-usage-card.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { DonutChartCardComponent } from './components/donut-chart-card/donut-chart-card.component';
import { CustomProgressBarComponent } from './components/custom-progress-bar/custom-progress-bar.component';
import { LicenseSummaryCardComponent } from './components/license-summary-card/license-summary-card.component';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { DialogsPortalComponent } from './dialog/dialogs-portal/dialogs-portal.component';
import { LicenseAgreementModalComponent } from './dialog/license-agreement-modal/license-agreement-modal.component';
import { TotalCountComponent } from './components/total-count/total-count.component';

@NgModule({
  declarations: [
    AviCardComponent,
    AviSearchComponent,
    AcpAlertComponent,
    AcpPageLoaderComponent,
    AcpLoaderComponent,
    AcpPageTitleComponent,
    AcpEmptyListComponent,
    AcpProfileCardComponent,
    AcpConfirmationModalComponent,
    LicenseUsageCardComponent,
    DonutChartComponent,
    DonutChartCardComponent,
    CustomProgressBarComponent,
    LicenseSummaryCardComponent,
    EmptyDataComponent,
    DialogsPortalComponent,
    LicenseAgreementModalComponent,
    TotalCountComponent,
    SecondsToTimePipe,
    SearchFilterPipe,
    FilterItemsPipe,
    SafePipe,
    RemoveUnderscorePipe,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ClarityImportsModule,
    VIPModule.forChild(),
  ],
  exports: [
    AviCardComponent,
    AviSearchComponent,
    AcpAlertComponent,
    AcpPageLoaderComponent,
    AcpLoaderComponent,
    AcpPageTitleComponent,
    AcpEmptyListComponent,
    AcpProfileCardComponent,
    AcpConfirmationModalComponent,
    LicenseUsageCardComponent,
    DonutChartComponent,
    DonutChartCardComponent,
    CustomProgressBarComponent,
    LicenseSummaryCardComponent,
    EmptyDataComponent,
    DialogsPortalComponent,
    LicenseAgreementModalComponent,
    TotalCountComponent,
    SecondsToTimePipe,
    SearchFilterPipe,
    FilterItemsPipe,
    SafePipe,
    RemoveUnderscorePipe,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ],
})

export class SharedModule { }
