/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  Component,
  Injectable,
  Type,
} from '@angular/core';

import {
  DEFAULT_INTERRUPTSOURCES,
  Idle,
} from '@ng-idle/core';

import { SessionTimeoutModalComponent } from '../../core/components/session-timeout/session-timeout-modal.component';
import { USER_SESSION } from '../constants';
import { DialogService } from './dialog.service';
import { LoginService } from './login.service';
import { SessionService } from './session.service';

const SESSION_TIMEOUT_MODAL = 'SESSION_TIMEOUT_MODAL';

/**
 * @name SessionTimeoutService
 * @module AcpModule
 * @desc Service deals with idle timeout. Service contains the
 * functions to handle the idle timeout scenarios like
 * extending session or logout of the application.
 * @author Rohit Gaikwad
 */
@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  constructor(
    public readonly idle: Idle,
    private readonly sessionService: SessionService,
    private readonly loginService: LoginService,
    private readonly dialogService: DialogService,
  ) {}

  /**
   * Function to initialise the idle timeout process.
   */
  public initialiseIdleTimeout(): void {
    // sets an idle timeout.
    this.idle.setIdle(USER_SESSION.IDLE_TIMEOUT);

    // sets a timeout period.
    this.idle.setTimeout(USER_SESSION.TIMEOUT);

    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.initialiseSubscriptions();
    this.reset();
  }

  /**
   * Stops the idle timeout counter & unsubscibes all the events.
   */
  public stopIdleTimeOut(): void {
    const { idle } = this;

    idle.stop();
    idle.onIdleStart.unsubscribe();
    idle.onTimeoutWarning.unsubscribe();
    idle.onIdleEnd.unsubscribe();
  }

  /**
   * Function to subscription to events required during idle timeout.
   */
  private initialiseSubscriptions(): void {
    // Subscibes to idleStart to open the warning popup when idle time starts.
    this.idle.onIdleStart.subscribe(() => {
      this.openSessionTimeoutModal();
    });

    /**
     * Subscibes to timeout to stop the timeout counter & log out from the application
     * when idle timeout is complete.
     */
    this.idle.onTimeout.subscribe(() => {
      this.stopIdleTimeOut();
      this.loginService.logout();
    });
  }

  /**
   * Function to reset the idle watch timer.
   */
  private reset(): void {
    this.idle.watch();
  }

  /**
   * Function to open session timeout modal.
   */
  private openSessionTimeoutModal(): void {
    this.dialogService.add({
      id: SESSION_TIMEOUT_MODAL,
      component: SessionTimeoutModalComponent as Type<Component>,
      componentProps: {
        onLogout: () => {
          this.stopIdleTimeOut();
          this.loginService.logout();
        },
        onContinueClick: () => {
          this.sessionService.refreshSession().subscribe((): void => {
            this.reset();
          });
        },
      },
    });
  }
}
