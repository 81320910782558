/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import {
  Component,
  Injectable,
  Type,
} from '@angular/core';

import { ObservableList } from './../utils/observable-list.utils';

export interface IDialogProps {
  id: string;
  component: Type<Component>;
  componentProps?: Record<string, any>;
}

/**
 * Service for adding and removing dialogs.
 * @author Abhinesh Gour
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService extends ObservableList<IDialogProps, string> {
  // eslint-disable-next-line class-methods-use-this
  protected getID(item: IDialogProps): string {
    return item.id;
  }
}
