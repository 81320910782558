/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CoreModule */

import {
  Component,
  OnInit,
} from '@angular/core';

import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AUTH_MODE } from '../../../shared/constants';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { AuthService } from '../../../shared/services/auth.service';
import { ROUTER_LINKS } from '../../../shared/router-links.constants';

import {
  accountNames,
  CSP_ALB_CLOUD_INTERNAL_ORG_MAP,
} from '../../../../modules/saas-ops/saas-ops-dashboard-grafana.constants';

import * as l10n from '../../core.module.l10n';
import * as globalL10n from '../../../../global.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ...l10nKeys } = l10n;

/**
 * @description Vertical nav component.
 *
 *     Component to handle Vertical nav for routing.
 *
 * @author Ratan Kumar
 */
@Component({
  selector: 'vertical-nav',
  templateUrl: './vertical-nav.component.html',
})
export class VerticalNavComponent implements OnInit {
  /**
   * Flag to check if user has entitlement or not.
   */
  public userWithEntitlements = false;

  /**
   * Flag to check if user has entitlement and org available.
   * Restricted mode is when, entitlement and org both are not available
   * In case of Restricted Mode, UI shows only Software nav with CRS tab hidden.
   */
  public isRestrictedMode = false;

  /**
   * Flag to check if user has Avi Pulse entitlement or not.
   */
  public entitlementsAllowed = false;

  public readonly routerLinks = ROUTER_LINKS;
  public readonly l10nKeys = l10nKeys;
  public readonly globalL10nKeys = globalL10nKeys;

  constructor(
    authService: AuthService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {
    this.userWithEntitlements = this.localStorageService.userWithEntitlements;
    this.isRestrictedMode = authService.isRestrictedMode();
  }

  public ngOnInit(): void {
    // Check Entitlement Status if return true then redirect to dashboard page.
    if (this.checkEntitlementStatus()) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  /**
   * Check Entitlement Status if it's false then we don't have to redirect to dashboard
   * Otherwise redirect to dashboard page.
   */
  public checkEntitlementStatus(): boolean {
    if (this.showSaasOperationsOptions) {
      return false;
    }

    // check if URL include 'health-service' or 'infra-service'
    // and if user is not entitled to see the SaaS Operations
    // then return true and redirect to dashboard page.
    const { url } = this.router;

    const routerCheck =
      url.includes('/saas-ops/health-service') || url.includes('/saas-ops/infra-service');

    return routerCheck;
  }

  /**
   * Check if current route is part of Software module.
   */
  public isSoftwareRoute(): boolean {
    const { url } = this.router;

    return url.includes('/software/');
  }

  /**
   * Used to show/hide SaaS Operation menu.
   */
  public get showSaasOperationsOptions(): boolean {
    const { deploymentEnv } = environment;
    const { eaName, eaNumber, authMode } = this.localStorageService;

    /*
      1. If authmode is VMW then use eaName to check if user is entitled to see SaaS Operations.
      2. If authmode is CSP or VMWCC then use eaNumber
        to check if user is entitled to see SaaS Operations.
    */
    if (authMode === AUTH_MODE.VMW) {
      return Boolean(eaName && accountNames.has(eaName));
    }

    return eaNumber && CSP_ALB_CLOUD_INTERNAL_ORG_MAP[deploymentEnv].includes(eaNumber);
  }
}
