
/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import {
  Observable,
  Subject,
} from 'rxjs';

/**
 * Service for adding and removing items that allows for subscriptions. Can be extended, for example
 * by the DialogService or NotificationService, to add and remove a specific set of props.
 * @typeParam T - Type of the item to be added to the list.
 * @typeParam U - Type of the unique ID.
 * @author Abhinesh Gour
 */
export abstract class ObservableList<T, U> {
  private readonly items: T[] = [];
  private readonly subject = new Subject<T[]>();

  public get items$(): Observable<T[]> {
    return this.subject.asObservable();
  }

  /**
   * Adds an item to the list of items. If the ID already exists, throws an error.
   */
  public add(item: T): void {
    const id = this.getID(item);

    if (this.hasId(id)) {
      throw new Error(`item with ID ${id} already exists`);
    }

    this.items.push(item);
    this.subject.next(this.items);
  }

  /**
   * Removes an item by ID. Looks through the list for the item that matches, and removes it from
   * the list.
   */
  public remove(id: U): void {
    const index = this.getIndex(id);

    if (index > -1) {
      this.items.splice(index, 1);
      this.subject.next(this.items);
    } else {
      throw new Error(`Item with ID ${id} not found`);
    }
  }

  public removeAll(): void {
    this.items.length = 0;
    this.subject.next(this.items);
  }

  public hasId(id: U): boolean {
    return this.getIndex(id) > -1;
  }

  public isEmpty(): boolean {
    return !this.items.length;
  }

  private getIndex(id: U): number {
    return this.items.findIndex((item: T) => this.getID(item) === id);
  }

  protected abstract getID(item: T): U;
}
