/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from '../../../../global.l10n';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Search component which can be used to search data.
 * @example
 *     <avi-search
 *         [placeholder]="Search by name"
 *         (onSearch)="handleSearch($event)"
 *     ></avi-search>
 *
 * @author sghare
 */
@Component({
  selector: 'avi-search',
  templateUrl: './avi-search.component.html',
  styleUrls: ['./avi-search.component.less'],
})
export class AviSearchComponent {
  /**
   * Placeholder for search input field.
   */
  @Input()
  public placeholder = '';

  /**
   * Fire search event when enter button is pressed.
   */
  @Output()
  public onSearch = new EventEmitter<string>();

  /**
   * Fires when user hit clear search icon.
   */
  @Output()
  public onClearSearch = new EventEmitter<string>();

  /**
   * Ref for search input. Used to set focus on search icon click.
   */
  @ViewChild('searchInput')
  private readonly searchInputRef: ElementRef = {} as ElementRef;

  /**
   * Token used for search.
   */
  public searchValue = '';

  /**
   * If true, show the search bar, otherwise show search icon only.
   */
  public showSearchBar = false;

  public readonly globalL10nKeys = globalL10nKeys;

  constructor(private readonly l10nService: L10nService) {
    this.placeholder =
      this.l10nService.getMessage(globalL10nKeys.searchPlaceholder);
  }

  /**
   * Handler for search form submit.
   */
  public search(value: string): void {
    this.onSearch.emit(value);
  }

  /**
   * Clear the search input field.
   */
  public clearSearch(): void {
    this.searchValue = '';
    this.showSearchBar = false;

    this.onClearSearch.emit('');
  }

  /**
   * Expand the search section with a search bar and a clear icon.
   */
  public expandSearchBar(): void {
    this.showSearchBar = true;

    setTimeout(() => {
      this.searchInputRef.nativeElement.focus();
    });
  }
}
