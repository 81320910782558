/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AUTH_MODE } from '../constants';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

/**
 * @description HTTP Interceptor service to add required request headers.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class HttpInterceptorsService implements HttpInterceptor {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService,
  ) {}

  /**
   *
   * @param req Request to be cloned
   * @param next Handling request
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiRequest = req.clone({ headers: req.headers });
    const { authMode, loggedIn } = this.localStorageService;
    const oauth2Token = localStorage.getItem('avi-pulse-ui-native_oauth2_token');

    // Add the required parameter to request header for valid users.
    if (authMode === AUTH_MODE.VMW && loggedIn && req.url.includes('/portal/')) {
      const { eaNumber: entitlementNumber } = this.localStorageService;

      // Append x-entitlement-id header if entitlement number is present.
      if (entitlementNumber) {
        apiRequest = req.clone({ headers: req.headers.append('x-entitlement-id', entitlementNumber)});
      }
    } else if (authMode !== AUTH_MODE.VMW && oauth2Token && req.url.includes('/portal/')) {
      // Get current org id and append to x-entitlement-id header.
      const orgId = this.localStorageService.eaNumber || this.authService.getOrgId();

      if (orgId) {
        apiRequest = req.clone({
          headers: req.headers.set('x-entitlement-id', orgId),
        });
      }

      // Append x-portal-csp-accesstoken header with access token value
      // to authenticate the token on backend.
      const { access_token: accessToken } = JSON.parse(oauth2Token);

      apiRequest = apiRequest.clone({
        headers: apiRequest.headers.set('x-portal-csp-accesstoken', accessToken),
      });
    }

    return next.handle(apiRequest);
  }
}
