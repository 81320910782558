/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import {
  Component,
  OnDestroy,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from './global.l10n';
import * as coreL10n from './modules/core/core.module.l10n';
import * as sharedL10n from './modules/shared/shared.module.l10n';

import { LocalStorageService } from './modules/shared/services/local-storage.service';
import { SessionTimeoutService } from './modules/shared/services/session-timeout.service';

const { ENGLISH: globalDictionary } = globalL10n;
const { ENGLISH: coreDictionary } = coreL10n;
const { ENGLISH: sharedDictionary } = sharedL10n;

/**
 * @name AcpRootComponent
 * @module AcpModule
 * @desc This component is the first component which gets rendered when application starts.
 * @author Ram Pal, Abhinesh Gour
 */
@Component({
  selector: 'acp-root',
  templateUrl: './acp-root.component.html',
  styleUrls: ['./acp-root.component.less'],
})
export class AcpRootComponent implements OnDestroy {
  constructor(
    private readonly l10nService: L10nService,
    private readonly localStorageService: LocalStorageService,
    private readonly sessionTimeoutService: SessionTimeoutService,
  ) {
    this.l10nService.registerSourceBundles(globalDictionary);
    this.l10nService.registerSourceBundles(coreDictionary);
    this.l10nService.registerSourceBundles(sharedDictionary);

    if (this.isLoggedIn) {
      // Initialize the idle timeout functions.
      this.sessionTimeoutService.initialiseIdleTimeout();
    }
  }

  /**
   * Returns true if user is logged in.
   */
  public get isLoggedIn(): boolean {
    const { loggedIn } = this.localStorageService;

    return loggedIn;
  }

  /** @override */
  public ngOnDestroy(): void {
    this.sessionTimeoutService.stopIdleTimeOut();
  }
}
