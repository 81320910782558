<div class="avi-search">
    <cds-icon
        *ngIf="!showSearchBar"
        class="avi-search-icon"
        shape="search"
        size="19"
        (click)="expandSearchBar()"
    ></cds-icon>
    <ng-container *ngIf="showSearchBar">
        <form
            clrForm
            class="avi-search__search-input"
            #searchForm="ngForm"
            (ngSubmit)="search(searchValue)"
        >
            <cds-search layout="horizontal">
                <!-- Empty label element placeholder required by cds-search component -->
                <label for="search-value"></label>
                <input
                    id="search-value"
                    name="search-value"
                    type="search"
                    #searchInput
                    [placeholder]="placeholder"
                    [(ngModel)]="searchValue"
                />
            </cds-search>
        </form>
        <cds-icon
            class="avi-search__clear-icon"
            shape="times-circle"
            size="20"
            (click)="clearSearch()"
        ></cds-icon>
    </ng-container>
</div>
