/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SaasOpsModule */

const moduleName = 'saas-ops';

export const durationLabel = `${moduleName}.durationLabel`;
export const applicationRuleServiceLabel = `${moduleName}.applicationRuleServiceLabel`;
export const crsServiceLabel = `${moduleName}.crsServiceLabel`;
export const supportCaseServiceLabel = `${moduleName}.supportCaseServiceLabel`;
export const registrationServiceLabel = `${moduleName}.registrationServiceLabel`;
export const authenticationServiceLabel = `${moduleName}.authenticationServiceLabel`;
export const ncsServiceLabel = `${moduleName}.ncsServiceLabel`;
export const softwareServiceLabel = `${moduleName}.softwareServiceLabel`;
export const clusterMonitorServiceLabel = `${moduleName}.clusterMonitorServiceLabel`;
export const ipReputationServiceLabel = `${moduleName}.ipReputationServiceLabel`;
export const customerExperienceLabel = `${moduleName}.customerExperienceLabel`;
export const threatIntelligenceLabel = `${moduleName}.threatIntelligenceLabel`;
export const infrastructureLabel = `${moduleName}.infrastructureLabel`;

export const ENGLISH = {
  [durationLabel]: `<b>Duration</b> Past {0}`,
  [applicationRuleServiceLabel]: 'Application Rule Service',
  [crsServiceLabel]: 'CRS Service',
  [supportCaseServiceLabel]: 'Support Case Service',
  [registrationServiceLabel]: 'Registration Service',
  [authenticationServiceLabel]: 'Authentication Service',
  [ncsServiceLabel]: 'NCS Service',
  [softwareServiceLabel]: 'Software Service',
  [clusterMonitorServiceLabel]: 'Cluster Monitor Service',
  [ipReputationServiceLabel]: 'IP Reputation Service',
  [customerExperienceLabel]: 'Customer Experience',
  [threatIntelligenceLabel]: 'Threat Intelligence',
  [infrastructureLabel]: 'Infrastructure',
};
