<div
  class="acp-alert"
  *ngIf="alert && alert.text"
>
  <div
    class="alert"
    [ngClass]="alertClass"
    role="alert"
  >
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon
            class="alert-icon"
            [shape]="alertShape"
          ></cds-icon>
        </div>
        <span class="alert-text">
          {{ alert.text }}
        </span>
      </div>
    </div>
    <cds-icon-button
      action="flat"
      aria-label="Close"
      (click)="onClose()"
    >
      <cds-icon
        aria-hidden="true"
        shape="close"
      ></cds-icon>
    </cds-icon-button>
  </div>
</div>
