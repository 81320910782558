/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SaasOpsModule */

import { environment } from '../../../environments/environment';

import {
  IService,
  ITimeStamp,
} from './saas-ops-dashboard.model';

import * as saasOpsl10n from './saas-ops.module.l10n';

const { ...saasOpsl10nKeys } = saasOpsl10n;

export const stage = 'stage';
export const prod = 'prod';
export const aviPulse = 'AviPulse';
export const dashboardURL = '/grafana/d-solo/pulseuid/pulse-dashboard-v2';
export const orgId = 'orgId=1';
export const theme = '&theme=light';
export const refreshTime = 'refresh=5s';

export const apiGrafanaEndPoint = environment.endpoint;

/**
 * Grafana dashboard end point.
 */
export const grafanaUrl = `${apiGrafanaEndPoint}${dashboardURL}?${orgId}`;

/**
 * issuerEnvironments will hold all environments.
 */
export const issuerEnvironments = new Set([stage, prod]);

/**
 * Account Names will hold all account name.
 * Used to check for account name to show Health and infra dashboards in VMW mode.
 */
export const accountNames = new Set([aviPulse]);

/**
 * Internal Orgs Info in different environment to show Health and infra dashboards in CSP mode.
 */
export const CSP_ALB_CLOUD_INTERNAL_ORG_MAP = {
  prod: ['7597e04f-4ccc-4f55-ac7c-fd0b43698502'], // AVI Pulse Prod Trial 3
  test: ['a69ef7d0-b27e-4d31-bb6f-9beafdf267de'], // AVI PULSE Stage Org 1
  stage: [
    'fa100f1c-2f3d-43b0-b461-46bb1cd5ea9a',
    'dcf902b0-2643-4e19-a788-afaaa602e8dc',
  ], // Avi Pulse Preview Trial Org 2, NSBU-ALB
};

/**
 * Grafana Duration List.
 */
export const timeStampList: ITimeStamp[] = [
  { name: '10 minute', value: 0.166667 },
  // eslint-disable-next-line
  { name: '30 minute', value: 0.50 },
  { name: '1 hour', value: 1 },
  { name: '6 hour', value: 6 },
  { name: '12 hour', value: 12 },
  { name: '24 hour', value: 24 },
];

/**
 * Type of service panel available on grafana dashboard.
 */
export enum Services {
  Apprule = 'Application Rule Service',
  CRS = 'CRS Service',
  Support = 'Support Case Service',
  Asset = 'Registration Service',
  Auth = 'Authentication Service',
  NCS = 'NCS Service',
  Software = 'Software Service',
  Ping = 'Cluster Monitor Service',
  IP = 'IP Reputation Service',
}

/**
 * Translation keys for service panel available on grafana dashboard.
 */
export const SERVICES_TRANSLATION_KEYS_MAP = {
  Apprule: saasOpsl10nKeys.applicationRuleServiceLabel,
  CRS: saasOpsl10nKeys.crsServiceLabel,
  Support: saasOpsl10nKeys.supportCaseServiceLabel,
  Asset: saasOpsl10nKeys.registrationServiceLabel,
  Auth: saasOpsl10nKeys.authenticationServiceLabel,
  NCS: saasOpsl10nKeys.ncsServiceLabel,
  Software: saasOpsl10nKeys.softwareServiceLabel,
  Ping: saasOpsl10nKeys.clusterMonitorServiceLabel,
  IP: saasOpsl10nKeys.ipReputationServiceLabel,
  Customer_Experience: saasOpsl10nKeys.customerExperienceLabel,
  Threat_Intelligence: saasOpsl10nKeys.threatIntelligenceLabel,
  Infrastructure: saasOpsl10nKeys.infrastructureLabel,
};

/**
 * All the service available on grafana dashboard.
 */
export const panelList: IService[] = [
  {
    name: Services.Asset,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.Asset,
    url: '',
    type: 'health',
    childPanel: { healthPid: 28, errorPid: 20 },
    category: 'Customer_Experience',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Customer_Experience,
    panelId: 11,
  }, {
    name: Services.Support,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.Support,
    url: '',
    type: 'health',
    childPanel: { healthPid: 32, errorPid: 33 },
    category: 'Customer_Experience',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Customer_Experience,
    panelId: 10,
  }, {
    name: Services.Software,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.Software,
    url: '',
    type: 'health',
    childPanel: { healthPid: 30, errorPid: 31 },
    category: 'Customer_Experience',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Customer_Experience,
    panelId: 14,
  }, {
    name: Services.CRS,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.CRS,
    url: '',
    type: 'health',
    childPanel: { healthPid: 22, errorPid: 21 },
    category: 'Threat_Intelligence',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Threat_Intelligence,
    panelId: 9,
  }, {
    name: Services.NCS,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.NCS,
    url: '',
    type: 'health',
    childPanel: { healthPid: 19, errorPid: 17 },
    category: 'Threat_Intelligence',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Threat_Intelligence,
    panelId: 13,
  }, {
    name: Services.IP,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.IP,
    url: '',
    type: 'health',
    childPanel: { healthPid: 24, errorPid: 25 },
    category: 'Threat_Intelligence',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Threat_Intelligence,
    panelId: 6,
  }, {
    name: Services.Apprule,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.Apprule,
    url: '',
    type: 'health',
    childPanel: { healthPid: 18, errorPid: 29 },
    category: 'Threat_Intelligence',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Threat_Intelligence,
    panelId: 8,
  }, {
    name: Services.Auth,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.Auth,
    url: '',
    type: 'infra',
    childPanel: { healthPid: 26, errorPid: 27 },
    category: 'Infrastructure',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Infrastructure,
    panelId: 12,
  }, {
    name: Services.Ping,
    displayName: SERVICES_TRANSLATION_KEYS_MAP.Ping,
    url: '',
    type: 'infra',
    childPanel: { healthPid: 34, errorPid: 35 },
    category: 'Infrastructure',
    displayCategory: SERVICES_TRANSLATION_KEYS_MAP.Infrastructure,
    panelId: 15,
  },
];
