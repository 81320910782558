/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CoreModule */

import {
  Component,
  OnInit,
  Type,
} from '@angular/core';

import { Router } from '@angular/router';
import { find } from 'lodash';

import { ManageAccountsComponent } from '../manage-accounts/manage-accounts.component';
import { UserDetailsComponent } from '../user-details/user-details.component';

import {
  IMyProfile,
  IEntitlementDetails,
} from '../../../shared/models/myprofile.model';

import { DialogService } from '../../../shared/services/dialog.service';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { LoginService } from '../../../shared/services/login.service';

import {
  BROADCOM_SUPPORT_PORTAL_URL,
  MY_VMWARE_PORTAL_URL,
  RESOURCES_URL,
} from '../../../shared/constants';

import { environment } from '../../../../../environments/environment';
import { ROUTER_LINKS } from '../../../shared/router-links.constants';
import * as l10n from '../../core.module.l10n';

const { ...l10nKeys } = l10n;

const USER_DETAILS_MODAL = 'USER_DETAILS_MODAL';
const MANAGE_ACCOUNTS_MODAL = 'MANAGE_ACCOUNTS_MODAL';

/**
 * @description Header component used in VMW mode.
 *
 * @author Rajawant Prajapati, Abhinesh Gour
 */
@Component({
  selector: 'acp-header',
  templateUrl: './acp-header.component.html',
  styleUrls: ['./acp-header.component.less'],
})
export class AcpHeaderComponent implements OnInit {
  /**
   * Holds Broadcom Support Portal url.
   */
  public readonly broadcomSupportPortalUrl: string = BROADCOM_SUPPORT_PORTAL_URL;

  /**
   * Swagger API endpoint.
   */
  public readonly swaggerApiEndpoint: string = `${environment.endpoint}/portal/api-docs`;

  /**
   * Holds Resources url.
   */
  public readonly resourcesUrl: string = RESOURCES_URL;

  /**
   * MYVMWARE Portal URL.
   */
  public readonly myVmwarePortalUrl: string = MY_VMWARE_PORTAL_URL;

  /**
   * Holds User Profile data.
   */
  public userProfile: IMyProfile;

  /**
   * Currently applied entitlement details.
   */
  public currentEntitlement: IEntitlementDetails;

  /**
   * Selected entitlement details.
   */
  public selectedEntitlement: IEntitlementDetails;

  public readonly dashboardRouterLink = ROUTER_LINKS.DASHBOARD;

  public readonly l10nKeys = l10nKeys;

  constructor(
    private readonly loginService: LoginService,
    private readonly dialogService: DialogService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {
    const { userProfile } = this.localStorageService;

    this.userProfile = userProfile;
  }

  /**
   * @override
   * Get current user details and set current account name.
   */
  public ngOnInit(): void {
    const { entitlementData } = this.userProfile;

    if (entitlementData.length) {
      const { eaNumber } = this.localStorageService;

      this.currentEntitlement = find(entitlementData, { eaNumber }) as IEntitlementDetails;
    }
  }

  /**
   * Logout from portal
   */
  public logoutFromPortal(): void {
    this.loginService.logout();
  }

  /**
   * Opens the dialog to display logged in user information.
   */
  public openDialogToViewUser(): void {
    this.dialogService.add({
      id: USER_DETAILS_MODAL,
      component: UserDetailsComponent as Type<Component>,
      componentProps: {
        closeDialog: () => this.dialogService.remove(USER_DETAILS_MODAL),
        userData: this.userProfile.userDetails,
      },
    });
  }

  /**
   * Open Manage account dialog.
   */
  public openManageAccountsDialog(): void {
    this.dialogService.add({
      id: MANAGE_ACCOUNTS_MODAL,
      component: ManageAccountsComponent as Type<Component>,
      componentProps: {
        closeDialog: () => this.dialogService.remove(MANAGE_ACCOUNTS_MODAL),
        onContinueClick: (companyName: string) => {
          // Component returns current company name if it's changed.
          if (companyName) {
            this.currentEntitlement.eaName = companyName;
          }
        },
        entitlementData: this.userProfile.entitlementData,
      },
    });
  }

  /**
   * Selects entitlement details with user action and set it to localStorage.
   */
  public selectEntitlement(entitlementData: IEntitlementDetails): void {
    this.currentEntitlement = entitlementData;

    const { eaNumber, eaName } = entitlementData;

    this.localStorageService.eaNumber = eaNumber;
    this.localStorageService.eaName = eaName;

    this.router.navigateByUrl(this.router.url);
  }
}
