/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CspAuthUtil } from '@vmw/csp-oauth2';
import { CspApiService } from '@vmw/csp-ngx-components';
import { AuthService } from './modules/shared/services/auth.service';
import { AUTH_CONSTANTS } from './modules/shared/constants';
import { ROUTER_LINKS } from './modules/shared/router-links.constants';

/**
 * Preload factory is executed during app initialization.
 */
export function PreloadFactory(preloadService: PreloadService): () => Promise<void> {
  return async () => {
    await preloadService.initialize();
  };
}

/**
 * @description
 *     PreloadService's initialize method is call from Preload factory.
 *     Preload Factory is the first thing that gets executed during app initialization.
 *     PreloadService is used to start the oAuth process from the UI to authorize the
 *     user and get the access token.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class PreloadService {
  constructor(
    private readonly authService: AuthService,
    private readonly cspApiService: CspApiService,
    private readonly router: Router,
  ) {}

  /**
   * Calls login method to authorize.
   */
  public async initialize(): Promise<void> {
    const params = new URLSearchParams(window.location.search);
    let orgId = params.get(AUTH_CONSTANTS.ORG_ID);
    const orgUrl = orgId ? AUTH_CONSTANTS.CSP_ORG_LINK + orgId : '';
    const lastVisitedUrl = window.location.pathname;

    let currentOrgUrl = orgUrl || CspAuthUtil.getRequestedOrgLink() || localStorage.getItem('currentOrgUrl') || '';

    // finally go back to the path based on successful authorization
    const loggedIn = await this.authService.login(currentOrgUrl);

    if (loggedIn) {
      // user is logged in successfully
      // set the new org-id and redirect the user
      orgId = this.authService.getOrgId();

      if (orgId) {
        currentOrgUrl = orgId ? AUTH_CONSTANTS.CSP_ORG_LINK + orgId : '';
        localStorage.setItem('currentOrgUrl', currentOrgUrl);
      }

      const loginRedirectPathFromState = this.authService.getLoginRedirectPathFromState();

      if (loginRedirectPathFromState) {
        this.router.navigateByUrl(loginRedirectPathFromState);
      } else if (lastVisitedUrl.includes(ROUTER_LINKS.DASHBOARD)) {
        this.router.navigateByUrl(ROUTER_LINKS.DASHBOARD);
      } else {
        // for auto login user should stay on current path
        this.router.navigateByUrl(lastVisitedUrl);
      }
    }
  }
}
