/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Injectable } from '@angular/core';

import {
  catchError,
  map,
  Observable,
} from 'rxjs';

import { find } from 'lodash';
import { IApiError } from '../models/generic-error.model';
import { environment } from '../../../../environments/environment';
import { IMyProfileApiResponse } from '../../shared/models/myprofile-api.model';
import { IMyProfile } from '../../shared/models/myprofile.model';
import { IEntitlementDetails } from '../models/myprofile.model';

import { ApiHandlerService } from './api-handler.service';
import { LocalStorageService } from './local-storage.service';
import { UtilityService } from './utility.service';
import { LoadingIndicatorService } from './loading-indicator.service';

/**
 * @name SessionService
 * @module AcpModule
 * @desc Service deals with getting the user session details
 * and refreshing the session in case of session timeout.
 * @author Rohit Gaikwad
 */
@Injectable()
export class SessionService {
  private readonly apiEndpoint: string = environment.endpoint;
  private readonly buildIssEnv = environment.deploymentEnv;
  private readonly basePath: string;
  private userProfile: IMyProfile;

  constructor(
    private readonly apiHandlerService: ApiHandlerService,
    private readonly localStorageService: LocalStorageService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.basePath = `${this.apiEndpoint}/portal`;
    this.userProfile = {} as IMyProfile;
  }

  /**
   * Get current user details
   */
  public getCurrentUserDetails(): Observable<IMyProfileApiResponse> {
    const url = `${this.basePath}/auth/sessiondetail/`;

    return this.apiHandlerService.get(url)
      .pipe(
        map((res: IMyProfileApiResponse) => {
          this.localStorageService.sessionRetryCount = 0;

          return res;
        }),
        catchError((err: IApiError) => {
          ++this.localStorageService.sessionRetryCount;
          this.loadingIndicatorService.hideLoader();

          throw err;
        }),
      );
  }

  /**
   * Refresh the user session.
   */
  public refreshSession(): Observable<void> {
    const url = `${this.basePath}/auth/refresh-session/`;

    return this.apiHandlerService.get(url);
  }

  /**
   * Function to get the session details & set app level details to local storage.
   */
  public getSessionDetails(): Observable<boolean> {
    this.loadingIndicatorService.showLoader();

    return this.getCurrentUserDetails()
      .pipe(
        map((rsp: IMyProfileApiResponse) => {
          this.loadingIndicatorService.hideLoader();
          this.userProfile = UtilityService.convertObjPropNamesToCamelCase(rsp) as IMyProfile;
          this.setUserStateToLocalStorage();

          const { loggedIn } = this.localStorageService;

          return loggedIn;
        }));
  }

  /**
   * Sets user details such as loggedIn, role and registration status to local storage.
   */
  private setUserStateToLocalStorage(): void {
    this.localStorageService.loggedIn = true;

    // set user profile to local storage service so that we don't have to fetch again.
    this.localStorageService.userProfile = this.userProfile;

    // Set authmode in localStorage id it's not set.
    const { authMode: authenticationMode } = this.localStorageService;

    if (!authenticationMode) {
      const { authContext: { authMode } } = this.userProfile;

      this.localStorageService.authMode = authMode.toLowerCase();
    }

    if (this.userProfile.entitlementData.length) {
      const { eaNumber } = this.localStorageService;
      let entitlementData: IEntitlementDetails | undefined;

      this.localStorageService.userWithEntitlements = true;

      if (!eaNumber) {
        entitlementData = find(this.userProfile.entitlementData, { isDefault: 'Y' });
      } else {
        entitlementData = find(this.userProfile.entitlementData,
          { eaNumber: this.localStorageService.eaNumber });
      }

      if (!entitlementData && this.userProfile.entitlementData.length) {
        entitlementData = this.userProfile.entitlementData[0];
      }

      if (entitlementData) {
        this.localStorageService.eaNumber = entitlementData.eaNumber;
        this.localStorageService.eaName = entitlementData.eaName;
      }
    } else {
      this.localStorageService.userWithEntitlements = false;
    }
  }
}
