/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CoreModule */

import {
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

import { SessionTimeoutService } from '../../../shared/services/session-timeout.service';
import * as l10n from '../../core.module.l10n';
import * as globalL10n from '../../../../global.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ...l10nKeys } = l10n;

@Component({
  selector: 'sesson-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
})
export class SessionTimeoutModalComponent {
  @Output()
  public onClose = new EventEmitter<void>();

  @Output()
  public onLogout = new EventEmitter<void>();

  @Output()
  public onContinueClick = new EventEmitter<void>();

  /**
   * To show the time remaining for session expiration.
   */
  public minutes = 0;
  public seconds = 0;

  public readonly l10nKeys = l10nKeys;
  public readonly globalL10nKeys = globalL10nKeys;

  constructor(private readonly sessionTimeoutService: SessionTimeoutService) {
    /**
     * Subscibe to timeoutWarning to update the timer displayed on the warning popup
     * during idle timeout.
     */
    this.sessionTimeoutService.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.minutes = Math.floor(countdown / 60);
      this.seconds = countdown % 60;
    });
  }

  /**
   * Continue to refresh session and close the dialog.
   */
  public continue(): void {
    this.onContinueClick.emit();
    this.onClose.emit();
  }

  public logout(): void {
    this.onLogout.emit();
    this.onClose.emit();
  }

  /**
   * Calculate & returns the session expiration time.
   */
  public getSessionTimeoutMessage(): string {
    let sessionExpirationTime = '';

    if (this.minutes !== 0) {
      sessionExpirationTime += ` ${ this.minutes } Minute`;
      sessionExpirationTime += ( this.minutes > 1 ) ? 's ' : ' ';
    }

    sessionExpirationTime += `${ this.seconds } Seconds`;

    return sessionExpirationTime;
  }
}
