/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Component } from '@angular/core';
import * as globalL10n from '../../../../global.l10n';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *  This component is used to show loader icon on a particular section of the page.
 * @author Amit Dubey, Ram Pal
 */
@Component({
  selector: 'acp-loader',
  templateUrl: './acp-loader.component.html',
})
export class AcpLoaderComponent {
  public readonly globalL10nKeys = globalL10nKeys;
}
