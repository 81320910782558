<clr-vertical-nav
  *ngIf="!isRestrictedMode; else restricted_mode_nav"
  [clrVerticalNavCollapsible]="true"
  class="acp__vertical-nav"
>
  <a
    clrVerticalNavLink
    [routerLink]="routerLinks.DASHBOARD"
    routerLinkActive="active"
    class="sel-dashboard"
  >
    <cds-icon
      clrVerticalNavIcon
      shape="dashboard"
    ></cds-icon>
    {{ l10nKeys.orgDashboardTitle | vtranslate }}
  </a>

  <clr-vertical-nav-group
    routerLinkActive="active"
  >
    <cds-icon
      clrVerticalNavIcon
      shape="cog"
    ></cds-icon>
    {{ l10nKeys.softwareTitle | vtranslate }}
    <clr-vertical-nav-group-children *clrIfExpanded="isSoftwareRoute()">
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.NSX_ALB"
        routerLinkActive="active"
      >
        {{ l10nKeys.nsxAlbTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.AKO"
        routerLinkActive="active"
      >
        {{ l10nKeys.akoTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.AMKO"
        routerLinkActive="active"
      >
        {{ l10nKeys.amkoTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.CRS"
        routerLinkActive="active"
      >
        {{ l10nKeys.crsTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.ADDITIONAL_TOOLS"
        routerLinkActive="active"
      >
        {{ l10nKeys.additionalToolsTitle | vtranslate }}
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

  <a
    *ngIf="userWithEntitlements"
    clrVerticalNavLink
    class="sel-controllers"
    routerLinkActive="active"
    [routerLink]="routerLinks.CONTROLLERS"
  >
    <cds-icon
      clrVerticalNavIcon
      shape="atom"
    ></cds-icon>
    {{ globalL10nKeys.controllersLabel | vtranslate }}
  </a>

  <a
    *ngIf="userWithEntitlements"
    clrVerticalNavLink
    class="sel-cases"
    routerLinkActive="active"
    [routerLink]="routerLinks.INVENTORY"
  >
    <cds-icon 
      clrVerticalNavIcon
      shape="checkbox-list"
    ></cds-icon>
    {{ l10nKeys.inventoryTitle | vtranslate }}
  </a>

  <clr-vertical-nav-group
    *ngIf="showSaasOperationsOptions"
    routerLinkActive="active"
  >
    <cds-icon
      shape="resource-pool"
      clrVerticalNavIcon
    ></cds-icon>
    {{ l10nKeys.saasOpsTitle | vtranslate }}
    <clr-vertical-nav-group-children *clrIfExpanded="this.checkEntitlementStatus()">
      <a
        clrVerticalNavLink
        routerLinkActive="active"
        [routerLink]="routerLinks.SAAS_OPS.HEALTH_SERVICE"
      >
        {{ l10nKeys.healthServicesTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        routerLinkActive="active"
        [routerLink]="routerLinks.SAAS_OPS.INFRA_SERVICE"
      >
        {{ l10nKeys.infrastructureServicesTitle | vtranslate }}
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
  <a
    clrVerticalNavLink
    [routerLink]="routerLinks.NOTIFICATIONS"
    routerLinkActive="active"
  >
    <cds-icon
      clrVerticalNavIcon
      shape="bell"
    ></cds-icon>
    {{ l10nKeys.notificationsTitle | vtranslate }}
  </a>
  <a
    clrVerticalNavLink
    [routerLink]="routerLinks.LICENSING"
    routerLinkActive="active"
  >
    <cds-icon
      clrVerticalNavIcon
      shape="certificate"
    ></cds-icon>
    {{ l10nKeys.licensingTitle | vtranslate }}
  </a>
</clr-vertical-nav>

<ng-template #restricted_mode_nav>
  <clr-vertical-nav
    [clrVerticalNavCollapsible]="true"
    class="acp__vertical-nav"
  >
    <a
      clrVerticalNavLink
      class="sel-software"
      routerLinkActive="active"
      [routerLink]="routerLinks.SOFTWARE_HOME"
    >
      <cds-icon
        clrVerticalNavIcon
        shape="cog"
      ></cds-icon>
      {{ l10nKeys.softwareTitle | vtranslate }}
    </a>
    <clr-vertical-nav-group-children>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.NSX_ALB"
        routerLinkActive="active"
      >
        {{ l10nKeys.nsxAlbTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.AKO"
        routerLinkActive="active"
      >
        {{ l10nKeys.akoTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.AMKO"
        routerLinkActive="active"
      >
        {{ l10nKeys.amkoTitle | vtranslate }}
      </a>
      <a
        clrVerticalNavLink
        [routerLink]="routerLinks.SOFTWARE.ADDITIONAL_TOOLS"
        routerLinkActive="active"
      >
        {{ l10nKeys.additionalToolsTitle | vtranslate }}
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav>
</ng-template>
