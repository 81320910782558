/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../shared/services/alert.service';

interface IAlert {
  type: string;
  text: string;
}

const AlertConfig = {
  danger: {
    class: 'alert-danger',
    shape: 'exclamation-circle',
  },
  warning: {
    class: 'alert-warning',
    shape: 'exclamation-triangle',
  },
  info: {
    class: 'alert-info',
    shape: 'info-circle',
  },
  success: {
    class: 'alert-success',
    shape: 'check-circle',
  },
};

@Component({
  selector: 'acp-alert',
  templateUrl: './acp-alert.component.html',
})
export class AcpAlertComponent implements OnInit, OnDestroy {
  public alert: IAlert = {} as IAlert;
  private readonly subscriptions: Subscription[];

  constructor(private readonly alertService: AlertService) {
    this.subscriptions = [];
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.alertService.getMessage()
      .subscribe(alert => {
        this.alert = alert;
      }));
    this.subscriptions.push(this.alertService
      .getMessage()
      .pipe(debounceTime(5000))
      .subscribe(() => (this.alert = {} as IAlert)));
  }

  public onClose(): void {
    this.alert = {} as IAlert;
  }

  public get alertClass(): string {
    return AlertConfig[this.alert.type].class;
  }

  public get alertShape(): string {
    return AlertConfig[this.alert.type].shape;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
