<clr-main-container>
  <div class="acp-root">
    <acp-page-loader class="acp-root__page-loader"></acp-page-loader>
    <acp-alert></acp-alert>
    <ng-container *ngIf="isLoggedIn">
      <acp-header></acp-header>
    </ng-container>
    <div class="content-container">
      <vertical-nav *ngIf="isLoggedIn"></vertical-nav>
      <div class="content-area acp-root__page">
        <dialogs-portal></dialogs-portal>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</clr-main-container>
