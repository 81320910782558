/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { attachComponentBindings } from '../../../shared/utils/component-rendering.utils';

import {
  DialogService,
  IDialogProps,
} from '../../../shared/services/dialog.service';

interface IDialog {
  id: string;
  componentRef: ComponentRef<Component>;
}

/**
 * Component used to display dialogs. All rendered dialogs will be contained within the
 * view of this component.
 * @author Abhinesh Gour
 */
@Component({
  selector: 'dialogs-portal',
  template: `
    <ng-template
      class="clr-wrapper"
      #dialogsContainer
    ></ng-template>`,
})
export class DialogsPortalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('dialogsContainer', {
    read: ViewContainerRef,
  }) private readonly dialogsContainerRef: ViewContainerRef;

  public dialogsList: IDialog[] = [];
  private subscription: Subscription;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly dialogService: DialogService,
  ) { }

  /**
   * Once view is initialized, check and destroy removed dialogs.
   * Also, check and render new dialogs from dialogsList.
   */
  public ngAfterViewInit(): void {
    this.subscription = this.dialogService.items$
      .subscribe((dialogs: IDialogProps[]): void => {
        this.destroyRemovedDialog(dialogs);
        this.setDialogs(dialogs);
        this.renderNewDialog();
      });
  }

  /** @override */
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private destroyRemovedDialog(incomingModalProps: IDialogProps[]): void {
    const incomingHash = incomingModalProps.reduce((hash, modal) => {
      hash[modal.id] = true;

      return hash;
    }, {});

    const removedDialogs = this.dialogsList.filter(dialog => !(dialog.id in incomingHash));

    removedDialogs.forEach((dialog): void => {
      const { hostView } = dialog.componentRef;
      const index = this.dialogsContainerRef.indexOf(hostView);

      this.dialogsContainerRef.remove(index);
    });
  }

  /**
   * Creates a modalsStack that matches the modalPropsStack coming from the
   * DialogService.
   */
  private setDialogs(incomingModalProps: IDialogProps[] = []): void {
    const existingModalRefs = this.dialogsList.reduce((hash, dialog) => {
      hash[dialog.id] = dialog.componentRef;

      return hash;
    }, {});

    this.dialogsList = incomingModalProps.map((props): IDialog => {
      const { id } = props;
      const componentRef = id in existingModalRefs ?
        existingModalRefs[id] :
        this.createComponentRef(props);

      return {
        componentRef,
        id,
      };
    });
  }

  private renderNewDialog(): void {
    this.dialogsList.forEach((dialog, stackIndex): void => {
      const { hostView } = dialog.componentRef;
      const index = this.dialogsContainerRef.indexOf(hostView);

      if (index < 0) {
        this.dialogsContainerRef.insert(hostView, stackIndex);
      }
    });
  }

  /**
   * Creates an instance of the modal component.
   */
  private createComponentRef(modalProps: IDialogProps): ComponentRef<Component> {
    const {
      component,
      componentProps = {},
      id,
    } = modalProps;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(this.dialogsContainerRef.injector);
    const defaultComponentProps = {
      onClose: () => this.dialogService.remove(id),
    };

    const extendedComponentProps: {} = {
      ...defaultComponentProps,
      ...componentProps,
    };

    attachComponentBindings(componentRef, extendedComponentProps);

    return componentRef;
  }
}
