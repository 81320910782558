/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @description Include repeated strings which can be used across different
 * components to avoid duplicacy.
 * @author Abhinesh Gour
 */

const moduleName = 'global';
const prefix = `${moduleName}`;

export const actionsLabel = `${prefix}.actionsLabel`;
export const addLabel = `${prefix}.addLabel`;
export const cancelLabel = `${prefix}.cancelLabel`;
export const couldntFindAnyObjectsPlaceholder = `${prefix}.couldntFindAnyObjectsPlaceholder`;
export const copyToClipboardLabel = `${prefix}.copyToClipboardLabel`;
export const continueLabel = `${prefix}.continueLabel`;
export const createLabel = `${prefix}.createLabel`;
export const dataNotFoundLabel = `${prefix}.dataNotFoundLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const downloadLabel = `${prefix}.downloadLabel`;
export const editLabel = `${prefix}.editLabel`;
export const loadingLabel = `${prefix}.loadingLabel`;
export const nameLabel = `${prefix}.nameLabel`;
export const noResultsFoundLabel = `${prefix}.noResultsFoundLabel`;
export const searchPlaceholder = `${prefix}.searchPlaceholder`;
export const typeLabel = `${prefix}.typeLabel`;
export const warningLabel = `${prefix}.warningLabel`;
export const versionLabel = `${prefix}.versionLabel`;
export const controllersLabel = `${prefix}.controllersLabel`;
export const itemsPerPageLabel = `${prefix}.itemsPerPageLabel`;
export const totalLabel = `${prefix}.totalLabel`;
export const noDataFoundLabel = `${prefix}.noDataFoundLabel`;
export const sizeInMegabytesLabel = `${prefix}.sizeInMegabytesLabel`;
export const noticeLabel = `${prefix}.noticeLabel`;

export const ENGLISH = {
  [actionsLabel]: 'Actions',
  [addLabel]: 'Add',
  [cancelLabel]: 'Cancel',
  [couldntFindAnyObjectsPlaceholder]: 'We couldn’t find any objects!',
  [copyToClipboardLabel]: 'Copy to clipboard',
  [continueLabel]: 'Continue',
  [createLabel]: 'Create',
  [dataNotFoundLabel]: 'Data not found',
  [descriptionLabel]: 'Description',
  [downloadLabel]: 'Download',
  [editLabel]: 'Edit',
  [loadingLabel]: 'Loading',
  [nameLabel]: 'Name',
  [noResultsFoundLabel]: 'No results found',
  [searchPlaceholder]: 'Search',
  [typeLabel]: 'Type',
  [warningLabel]: 'Warning',
  [versionLabel]: 'Version',
  [controllersLabel]: 'Controllers',
  [itemsPerPageLabel]: 'Items per page',
  [totalLabel]: 'Total',
  [noDataFoundLabel]: 'No Data Found',
  [sizeInMegabytesLabel]: 'Size (Megabytes)',
  [noticeLabel]: 'Notice',
};
