/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CoreModule */

import {
  Component,
  OnInit,
} from '@angular/core';

import { Router } from '@angular/router';

import {
  CspHeaderOptions,
  Org,
} from '@vmw/csp-ngx-components';

import { OAuth2Client } from '@vmw/csp-oauth2';
import { CSP_HEADER_CONFIG } from '../../../shared/constants';
import { LoginService } from '../../../shared/services/login.service';
import { AuthService } from '../../../shared/services/auth.service';

/**
 * @description CSP Header component used in VMWCC/CSP mode.
 *
 *     Component to handle CSP header configuration.
 *
 * @author Rajawant Prajapati
 */
@Component({
  selector: 'vmw-pulse-csp-header',
  templateUrl: 'vmw-pulse-csp-header.component.html',
})
export class VmwPulseCspHeaderComponent implements OnInit {
  /**
   * Header Options for CSP Header.
   */
  public readonly headerOptions: CspHeaderOptions = new CspHeaderOptions();

  /**
   * Access token to be passed to csp header.
   */
  public accessToken?: string | null;

  constructor(
    private readonly loginService: LoginService,
    private readonly oAuth2Client: OAuth2Client,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  /**
   * Set initial CSP Header and Service values.
   */
  public ngOnInit(): void {
    this.accessToken = this.authService.getToken();

    this.setCspHeaderOption();
  }

  /**
   * Logout from portal.
   */
  public async signOut(): Promise<void> {
    this.loginService.logout();
  }

  /**
   * Handles org change.
   */
  public orgChange(newOrg: Org): void {
    this.oAuth2Client.authorize({ lastUrl: this.router.url }, newOrg.refLink);
  }

  /**
   * Method to set CSP Header options.
   */
  private setCspHeaderOption(): void {
    // App setting option
    this.headerOptions.showBackdrop = true;
    this.headerOptions.showHeaderBeforeFullyInitialized = true;

    // Base route option setting.
    this.headerOptions.baseRoute = CSP_HEADER_CONFIG.baseRoute;

    // Doc option setting.
    this.headerOptions.disableDocsSearch = true;

    // Titles option setting.
    this.headerOptions.title = CSP_HEADER_CONFIG.title;
    this.headerOptions.subTitle = CSP_HEADER_CONFIG.subTitle;
    this.headerOptions.appTitleAriaLabel = CSP_HEADER_CONFIG.appTitleAriaLabel;

    // Org option setting.
    this.headerOptions.showOrgSwitcher = true;

    // Hide the option to change default org.
    this.headerOptions.enableChangeDefaultOrg = false;
    this.headerOptions.disableOrgBranding = true;

    // Help menu option
    this.headerOptions.showHelpMenu = false;

    // Notifications Icon option setting in menu
    this.headerOptions.showNotificationsMenu = false;

    // Login option setting.
    this.headerOptions.enableSignout = true;
    this.headerOptions.showSignIn = false;
  }
}
