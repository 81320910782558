/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

const SAAS = {
  DEPLOYMENT: '/saas/deployment',
  TENANT: '/saas/tenant',
  ADMIN: '/saas/admin',
};

const SAAS_OPS = {
  HEALTH_SERVICE: '/saas-ops/health-service',
  INFRA_SERVICE: '/saas-ops/infra-service',
};

const SOFTWARE = {
  ADDITIONAL_TOOLS: '/software/additional-tools',
  AKO: '/software/ako',
  AMKO: '/software/amko',
  BETA: '/software/beta',
  CRS: '/software/crs',
  NSX_ALB: '/software/nsx-alb',
};

export const ROUTER_LINKS = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  LICENSES: '/licenses',
  PRODUCT_LOCATION: '/product-location',
  CONTROLLERS: '/controllers',
  EDIT_DEPLOYMENT: '/edit-deployment',
  SAAS_HOME: '/saas',
  SAAS,
  SAAS_OPS_HOME: '/saas-ops',
  SAAS_OPS,
  SOFTWARE_HOME: '/software',
  SOFTWARE,
  ERROR_PAGE: '/error-page',
  SUPPORT_CASES: '/support-cases',
  NOTIFICATIONS: '/notifications',
  INVENTORY: '/inventory',
  LICENSING: '/licensing',
};
