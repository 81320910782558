/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VmwComponentsModule } from '@vmw/ngx-components';

import {
  CspApiService,
  CspComponentsModule,
  CspTokenService,
} from '@vmw/csp-ngx-components';

import {
  OAuth2Module,
  OAuth2ModuleConfiguration,
} from '@vmw/ngx-csp-oauth2';

import {
  VmwContextualHelpConfig,
  VmwContextualHelpModule,
  VmwContextualHelpService,
} from '@vmw/ngx-contextual-help';

import {
  I18nContext,
  LocaleService,
  VIPModule,
  VIPService,
} from '@vmw/ngx-vip';

// Modules
import { AcpRoutingModule } from './acp-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { CoreModule } from './modules/core/core.module';
import { ClarityImportsModule } from './clarity-imports.module';

// Services
import { AuthGuard } from './auth.guard';
import { AuthService } from './modules/shared/services/auth.service';
import { RestrictedModeGuard } from './restricted-mode.guard';
import { HttpInterceptorsService } from './modules/shared/services/http-interceptors.service';
import { SessionService } from './modules/shared/services/session.service';

import {
  PreloadFactory,
  PreloadService,
} from './preload.service';

// Components
import { AcpRootComponent } from './acp-root.component';
import { SaasOpsComponent } from './modules/saas-ops/saas-ops.component';
import { HealthServiceComponent } from './modules/saas-ops/health-service/health-service.component';
import { VmwPulseCspHeaderComponent } from './modules/core/components/vmw-pulse-csp-header/vmw-pulse-csp-header.component';
import { ErrorPageComponent } from './modules/shared/error-page/error-page.component';

// Configs
import { portalOauth2ConfigFactory } from './oauth.config';
import { initVIPConfig } from './modules/core/services/i18n/i18n.config';

import './modules/shared/services/clarity-icons';

@NgModule({
  declarations: [
    AcpRootComponent,
    HealthServiceComponent,
    SaasOpsComponent,
    VmwPulseCspHeaderComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AcpRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    CspComponentsModule.forRoot(),
    VmwContextualHelpModule.forRoot({}),
    VmwComponentsModule.forRoot(),
    VIPModule.forRoot(),
    OAuth2Module,
    SharedModule,
    ClarityImportsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [PreloadService],
      multi: true,
      useFactory: PreloadFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initVIPConfig,
      deps: [
        VIPService,
        LocaleService,
        I18nContext,
      ],
      multi: true,
    },
    AuthService,
    PreloadService,
    AuthGuard,
    RestrictedModeGuard,
    SessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorsService,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: OAuth2ModuleConfiguration,
      useFactory: portalOauth2ConfigFactory,
      deps: [CspApiService],
    },
    CspTokenService,
    VmwContextualHelpService,
    VmwContextualHelpConfig,
  ],
  bootstrap: [AcpRootComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ],
})
export class AcpModule {}
