/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CoreModule */

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { Router } from '@angular/router';

import { find } from 'lodash';
import { IManagedAccount } from '../../../shared/models/managed-account.model';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import * as l10n from '../../core.module.l10n';
import * as globalL10n from '../../../../global.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ...l10nKeys } = l10n;

/**
 * @description Component to show the list of accounts and select account.
 *
 * @author Rajawant Prajapati
 */
@Component({
  selector: 'manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.less'],
})
export class ManageAccountsComponent implements OnInit {
  /**
   * Input for entitlements data.
   */
  @Input()
  public entitlementData: IManagedAccount[] = [];

  @Output()
  public onClose = new EventEmitter<void>();

  @Output()
  public onContinueClick = new EventEmitter<string>();

  public selectedAccount?: IManagedAccount;
  public searchValue = '';

  /**
   * flag denoting no results found from text search
   */
  public noSearchResult = false;

  /**
   * list of accounts to display
   */
  public filteredAccounts: IManagedAccount[] = [];

  public readonly l10nKeys = l10nKeys;
  public readonly globalL10nKeys = globalL10nKeys;

  /**
   * Currently selected tenant Id
   */
  private eaNumber = '';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.filteredAccounts = this.entitlementData;
    this.eaNumber = this.localStorageService.eaNumber;

    if (this.isUserWithEntitlements()) {
      this.selectedAccount = find(this.filteredAccounts, {eaNumber: this.eaNumber});

      if (!this.selectedAccount && this.filteredAccounts.length) {
        this.selectedAccount = this.filteredAccounts[0];
      }
    }
  }

  /**
   * Returns whether user has any entitlements or not.
   */
  public isUserWithEntitlements(): boolean {
    return this.localStorageService.userWithEntitlements;
  }

  /**
   * Sets the selected account.
   */
  public setAccount(account: any): void {
    this.selectedAccount = account;
  }

  /**
   * Text search on list of managed accounts.
   * @param query - text to search
   */
  public searchAccounts(query: string): void {
    if (query && this.entitlementData.length) {
      const searchString = query.toLowerCase();

      this.filteredAccounts = this.entitlementData.filter(
        (account: IManagedAccount) => account.eaName.toLowerCase().includes(searchString),
      );

      this.noSearchResult = this.filteredAccounts.length === 0;

      return;
    }

    this.filteredAccounts = this.entitlementData;
  }

  /**
   * When the user hits clear search icon, clear search value and show complete entitlement list.
   */
  public handleClearSearch(): void {
    this.searchValue = '';
    this.filteredAccounts = this.entitlementData;
  }

  /**
   * Stores selected account data in local storage.
   */
  public changeAccount(): void {
    if (this.selectedAccount) {
      const { permissions, eaNumber, eaName } = this.selectedAccount;

      this.localStorageService.isSuperUser = permissions.isSuperUser;
      this.localStorageService.eaNumber = eaNumber;
      this.onContinueClick.emit(eaName);
      this.onClose.emit();
    } else {
      this.onClose.emit();
    }

    this.router.navigateByUrl(this.router.url);
  }

  public cancel(): void {
    this.onClose.emit();
  }
}
