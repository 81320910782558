/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CoreModule */

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { environment } from '../../../../../environments/environment';

import { AlertService } from '../../../shared/services/alert.service';
import { ApiHandlerService } from '../../../shared/services/api-handler.service';
import { LoadingIndicatorService } from '../../../shared/services/loading-indicator.service';
import { LocalStorageService } from '../../../shared/services/local-storage.service';

import { IApiError } from '../../../shared/models/generic-error.model';
import { IMyUserDetails } from '../../../shared/models/myprofile-api.model';

import {
  IEntitlementDetails,
  IUserEntitlementRespose,
} from '../../../shared/models/myprofile.model';

import * as l10n from '../../core.module.l10n';

const { ...l10nKeys } = l10n;

/**
 * Modal to display user details.
 */
@Component({
  selector: 'user-details',
  templateUrl: './user-details.component.html',
})
export class UserDetailsComponent implements OnInit {
  @Input()
  public userData: IMyUserDetails;

  /**
   * Function to be called when the modal-close button is clicked.
   */
  @Output()
  public onClose = new EventEmitter<void>();

  public readonly l10nKeys = l10nKeys;

  public currentEntitlementsDetails: IEntitlementDetails;

  public currentEaDetails: {
    eaNumber: string;
    eaName: string;
    siteId: string;
  }

  constructor(
    private readonly alertService: AlertService,
    private readonly apiHandlerService: ApiHandlerService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  /** @override */
  public ngOnInit(): void {
    this.fetchEntitlements();
  }

  public cancel(): void {
    this.onClose.emit();
  }

  public fetchEntitlements(): void {
    const url = `${environment.endpoint}/portal/auth/user_entitlement`;

    this.loadingIndicatorService.showLoader();
    this.apiHandlerService.get(url).subscribe({
      next: (entitlements: IUserEntitlementRespose) => {
        this.currentEntitlementsDetails = entitlements?.entitlement_data.find(
          entitlement => entitlement?.eaNumber === this.localStorageService?.eaNumber,
        ) as IEntitlementDetails;
        this.splitEntitlementData();
        this.loadingIndicatorService.hideLoader();
      },
      error: (error: IApiError) => {
        this.alertService.error(error.error);
        this.loadingIndicatorService.hideLoader();
      },
    });
  }

  /**
   * Split entitlement details to retrieve eaNumber, siteId and eaName.
   * Where:
   * 1. EA number is expected to be in a colon-separated string format (e.g., 6322878:16313740),
   * where the first part represents the EA number and the second part denotes the site ID.
   * 2. EA name is formatted as Name:SiteId (e.g., MLC LTD:16313740).```
   */
  private splitEntitlementData(): void {
    const [eaName] = this.currentEntitlementsDetails?.eaName.split(':');

    if (this.currentEntitlementsDetails.eaNumber) {
      const [eaNumber, siteId] = this.currentEntitlementsDetails.eaNumber.split(':');

      this.currentEaDetails = { eaNumber, siteId, eaName };
    }
  }
}
