/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {
  map,
  Observable,
} from 'rxjs';

import { LocalStorageService } from './modules/shared/services/local-storage.service';
import { SessionService } from './modules/shared/services/session.service';
import { ROUTER_LINKS } from './modules/shared/router-links.constants';
import { IApiError } from './modules/shared/models/generic-error.model';

import {
  AUTH_MODE,
  CUSTOMER_CONNECT_LOGIN_ERROR_CODE,
} from './modules/shared/constants';

/**
 * @description Auth Guard to check if user is logged in or not.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    { url }: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.validateLogin().pipe(map((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        this.redirectToLogin(url);

        return false;
      }

      return true;
    }));
  }

  /**
   * Check if user is logged.
   * Separate getSessionDetails call is required to confirm that user is logged in
   * and to set the app level details to local storage.
   * as the login is handled by third party this call is required.
   */
  private validateLogin(): Observable<boolean> {
    return new Observable(resolve => {
      const { loggedIn: isLoggedIn } = this.localStorageService;

      // If user is already logged in, we don't need to call session details api.
      if (isLoggedIn) {
        resolve.next(isLoggedIn);
      } else {
        this.sessionService.getSessionDetails().subscribe(loggedIn => {
          resolve.next(loggedIn);
        },
        (err: IApiError) => {
          console.error('error:', err);

          /**
           * AuthError: 'E015-027'
           * To identify user it trying to logged in with customerconnect account (VMW mode).
           */
          if (err.error.includes(CUSTOMER_CONNECT_LOGIN_ERROR_CODE)) {
            this.localStorageService.authMode = AUTH_MODE.VMW;
            this.redirectToLogin(location.href);
          }
        });
      }
    });
  }

  /**
   * Redirects user to login & also sets the url
   * to which user will be redirected after successful login.
   */
  private redirectToLogin(url: string): void {
    this.router.navigateByUrl(ROUTER_LINKS.LOGIN, { state: { redirectUrl: url } });
  }
}
