/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import '@cds/core/icon/register.js';

import {
  atomIcon,
  betaIcon,
  cogIcon,
  checkboxListIcon,
  dashboardIcon,
  downloadIcon,
  ClarityIcons,
  mapMarkerIcon,
  resourcePoolIcon,
  userIcon,
  searchIcon,
  timesCircleIcon,
  circleIcon,
  certificateIcon,
  cloudIcon,
  vmwAppIcon,
  rackServerIcon,
  networkSwitchIcon,
  warningStandardIcon,
  pieChartIcon,
  shareIcon,
  lineChartIcon,
  circleArrowIcon,
  trashIcon,
  treeViewIcon,
  wrenchIcon,
  noteIcon,
  helpIcon,
} from '@cds/core/icon';

const iconList = [
  atomIcon,
  betaIcon,
  cogIcon,
  checkboxListIcon,
  dashboardIcon,
  downloadIcon,
  resourcePoolIcon,
  mapMarkerIcon,
  userIcon,
  searchIcon,
  timesCircleIcon,
  circleIcon,
  certificateIcon,
  cloudIcon,
  vmwAppIcon,
  rackServerIcon,
  networkSwitchIcon,
  warningStandardIcon,
  pieChartIcon,
  shareIcon,
  lineChartIcon,
  circleArrowIcon,
  trashIcon,
  treeViewIcon,
  wrenchIcon,
  noteIcon,
  helpIcon,
];

ClarityIcons.addIcons(...iconList);
