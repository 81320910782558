/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  Component,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

import { IMyUserDetails } from '../../models/myprofile-api.model';
import * as l10n from '../../../core/core.module.l10n';

const { ...l10nKeys } = l10n;

/**
 * @description
 *  This Component is used to display user profile information such as First name,
 *    Last name and Email address. It also has option to logout from application.
 * @author Ram Pal
 */
@Component({
  selector: 'acp-profile-card',
  templateUrl: './acp-profile-card.component.html',
  styleUrls: ['./acp-profile-card.component.less'],
})
export class AcpProfileCardComponent {
  @Output() public signOut = new EventEmitter();
  @Output() public showProfile = new EventEmitter();
  @Input() public profile = {} as IMyUserDetails;

  public readonly l10nKeys = l10nKeys;

  public onSignOut(): void {
    this.signOut.emit();
  }

  public onShowProfile(): void {
    this.showProfile.emit();
  }
}
