<div class="error-page">
    <vmw-pulse-csp-header></vmw-pulse-csp-header>
    <div class="error-page__container">
        <cds-alert-group
            status="info"
            class="error-page__banner-info"
            *ngIf="isInfoBannerVisible"
        >
            <cds-alert
                closable
                (closeChange)="hideInfoBanner()"
            >
            <span class="error-page__banner-info-label">{{ globalL10nKeys.noticeLabel | vtranslate }}</span>
            <span [innerHTML]="bannerHTMLText"></span>
            </cds-alert>
        </cds-alert-group>
        <h3 class="error-page__error-message">
            {{ errorMessage }}
        </h3>
    </div>
</div>
