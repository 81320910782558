<header class="header header-6 acp-header">
  <div class="branding">
    <a
      class="nav-link"
      [routerLink]="dashboardRouterLink"
    >
      <cds-icon shape="vm-bug"></cds-icon>
      <span class="title sel-header-title">{{ l10nKeys.nsxAlbCloudServicesTitle | vtranslate }}</span>
    </a>
  </div>
  <div class="header-actions">
    <div class="acp-divider"></div>
    <clr-dropdown>
      <cds-button
        action="flat"
        status="inverse"
        class="sel-select-account-header"
        clrDropdownTrigger
        aria-label="{{ l10nKeys.openUserProfileLabel | vtranslate }}"
      >
        {{ (currentEntitlement?.eaName ?? '') | uppercase }}
        <cds-icon
          shape="angle"
          direction="down"
          size="xs"
        ></cds-icon>
      </cds-button>
      <clr-dropdown-menu
        *clrIfOpen
        clrPosition="bottom-right"
      >
        <a
          *ngFor="let entitlement of userProfile?.entitlementData"
	        class="sel-department-item"
          clrDropdownItem
          (click)="selectEntitlement(entitlement)"
        >
          {{ entitlement?.eaName ?? '' }}
        </a>
      </clr-dropdown-menu>
    </clr-dropdown>
    <div class="acp-divider"></div>
    <clr-dropdown>
      <cds-icon-button
        action="flat"
        status="inverse"
        cds-layout="p:none"
        clrDropdownTrigger
        aria-label="resources"
      >
        <cds-icon
          shape="ellipsis-vertical"
          size="15"
        ></cds-icon>
      </cds-icon-button>
      <clr-dropdown-menu
        *clrIfOpen
        clrPosition="bottom-right"
      >
        <a
          clrDropdownItem
          href="{{ broadcomSupportPortalUrl }}"
        >
          {{ l10nKeys.broadcomSupportPortalLabel | vtranslate }}
        </a>
        <div class="dropdown-divider"></div>
        <a
          clrDropdownItem
          href="{{ swaggerApiEndpoint }}"
          target="_blank"
        >
          {{ l10nKeys.portalApiDocumentationLabel | vtranslate }}
        </a>
        <div class="dropdown-divider"></div>
        <a
	        class="sel-resources-dropdown"
          clrDropdownItem
          href="{{ resourcesUrl }}"
          target="_blank"
        >
          {{ l10nKeys.resourcesLabel | vtranslate }}
        </a>
      </clr-dropdown-menu>
    </clr-dropdown>
    <div class="acp-divider"></div>
    <clr-dropdown>
      <cds-icon-button
        action="flat"
        status="inverse"
        class="sel-user-setting"
        clrDropdownTrigger
        aria-label="{{ l10nKeys.toggleUserSettingsMenuLabel | vtranslate }}"
      >
        <cds-icon
          shape="user"
          size="md"
        ></cds-icon>
        <cds-icon
          shape="angle"
          direction="down"
          size="xs"
        ></cds-icon>
      </cds-icon-button>
      <clr-dropdown-menu
        *clrIfOpen
        clrPosition="bottom-right"
      >
        <acp-profile-card
          (signOut)="logoutFromPortal()"
          (showProfile)="openDialogToViewUser()"
          [profile]="userProfile.userDetails"
        ></acp-profile-card>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</header>
