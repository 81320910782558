/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module DashboardModule*/

import { Injectable } from '@angular/core';

import {
  Observable,
  throwError,
} from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ApiHandlerService } from '../../shared/services/api-handler.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { AuthService } from '../../shared/services/auth.service';
import { AUTH_MODE } from '../../shared/constants';
import { IOrgDashboard } from '../org-dashboard.types';
import { IPortalConfigResponse } from '../../software/software.types';

@Injectable({
  providedIn: 'root',
})
export class OrgDashboardService {
  private readonly apiEndpoint: string = environment.endpoint;
  private readonly supportabilityAPI: string;

  constructor(
    private readonly apiHandlerService: ApiHandlerService,
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService,
  ) {
    this.supportabilityAPI = `${this.apiEndpoint}/portal/supportability`;
  }

  /**
   * Return organization details from inventory.
   */
  public getOrgInventoryData(): Observable<IOrgDashboard> {
    const eaNumber = this.localStorageService.authMode === AUTH_MODE.VMW ?
      this.localStorageService.eaNumber : this.authService.getOrgId();

    if (!eaNumber) {
      return throwError({message: 'A problem occurred loading Organization ID, please try logging back in.'});
    }

    const url = `${this.supportabilityAPI}/org-inventory/${eaNumber}`;

    return this.apiHandlerService.get(url);
  }

  /**
   * Fetch general information banner text.
   */
  public getBannerText(): Observable<IPortalConfigResponse> {
    const url = `${this.apiEndpoint}/portal/config/pulse?key=DASHBOARD_BANNER&type=SERVER`;

    return this.apiHandlerService.get(url);
  }
}
