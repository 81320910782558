/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import { Injectable } from '@angular/core';
import { OAuth2Client } from '@vmw/csp-oauth2';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { AUTH_MODE } from '../constants';
import { ROUTER_LINKS } from '../router-links.constants';

const CUSTOMER_CONNECT_TOKEN = 'customerconnect';
const VANTAGE_TOKEN = 'vantage';

/**
 * @description Login service to handle login and logout functionality.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class LoginService {
  /**
   * Holds environment endpoint.
   */
  private readonly apiEndpoint: string = environment.endpoint;

  /**
   * Holds application domain name.
   */
  private readonly appDomain: string;

  /**
   * Holds login url.
   */
  private readonly loginUrl: string;

  /**
   * Holds sso login url in vmw mode.
   */
  private readonly vmwSsoLoginUrl: string;

  /**
   * Holds logout url.
   */
  private readonly logoutUrl: string;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly window: Window,
    private readonly oAuth2Client: OAuth2Client,
  ) {
    const { state } = window.history;
    const redirectUrl = state?.redirectUrl || ROUTER_LINKS.DASHBOARD;

    this.appDomain = window.origin;
    this.loginUrl = `${this.appDomain}/login`;
    this.logoutUrl = `${this.apiEndpoint}/portal/auth/logout/?return_url=${this.loginUrl}`;
    this.vmwSsoLoginUrl =
      `${this.apiEndpoint}/portal/auth/ssologin/?return_url=${this.appDomain}${redirectUrl}`;

    this.redirectToCustomerConnectAuthentication();
  }

  /**
   * Logs out of the application.
   */
  public async logout(): Promise<void> {
    const { authMode } = this.localStorageService;

    if (authMode !== AUTH_MODE.VMW && authMode !== AUTH_MODE.BCOM) {
      await this.oAuth2Client.logout(this.loginUrl);
    } else {
      location.assign(`${this.logoutUrl}?authmode=${authMode}`);
    }

    localStorage.clear();
  }

  /**
   * Logout from previous mode when changing the product location.
   */
  public logoutFromPreviousMode(prevMode: string): void {
    const { authMode } = this.localStorageService;

    this.localStorageService.reset();

    if (prevMode !== AUTH_MODE.VMW && localStorage.getItem('currentOrgUrl')) {
      localStorage.removeItem('currentOrgUrl');

      // logout from csp mode and initiate login in vmw mode.
      this.oAuth2Client.logout(this.loginUrl).then(() => {
        if (authMode === AUTH_MODE.VMW) {
          location.assign(this.vmwSsoLoginUrl + '&authmode=websso');
        }
      });
    } else {
      location.assign(`${this.logoutUrl}?authmode=${authMode}`);
    }
  }

  /**
   * Redirects user to login page.
   */
  public login(): void {
    const { authMode } = this.localStorageService;

    if (authMode === AUTH_MODE.VMW) {
      location.assign(this.vmwSsoLoginUrl + '&authmode=websso');
    } else if (authMode === AUTH_MODE.BCOM) {
      location.assign(`${environment.endpoint}/portal/auth/broadcomui`);
    } else {
      this.oAuth2Client.authorize({ loginRedirectPath: ROUTER_LINKS.DASHBOARD });
    }
  }

  /**
   * Returns true if AuthMode is 'VMW'.
   * Used to show/hide license usage card and sub-nav on Dashboard page.
   */
  public get isOnPremMode(): boolean {
    const { authMode } = this.localStorageService;

    return authMode === AUTH_MODE.VMW;
  }

  /**
   * If user is not logged in and a request is coming from customerconnect for
   * Software vantage/download page, set authmode to VMW and redirect for authentication.
   */
  private redirectToCustomerConnectAuthentication(): void {
    if (
      !this.localStorageService.loggedIn &&
      document.referrer.includes(CUSTOMER_CONNECT_TOKEN) &&
      location.pathname.includes(VANTAGE_TOKEN)
    ) {
      // eslint-disable-next-line max-len
      const loginUrl = `${this.apiEndpoint}/portal/auth/ssologin/?state=${this.appDomain}${location.pathname}&authmode=websso`;

      this.localStorageService.authMode = AUTH_MODE.VMW;
      location.assign(loginUrl);
    }
  }
}
