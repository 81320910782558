/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpRoutingModule */

import { NgModule } from '@angular/core';

import {
  RouterModule,
  Routes,
  Router,
  PreloadAllModules,
} from '@angular/router';

import { AuthGuard } from './auth.guard';
import { RestrictedModeGuard } from './restricted-mode.guard';
import { LoginComponent } from './modules/core/components/login/login.component';
import { ErrorPageComponent } from './modules/shared/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    loadChildren: async () => (await import('./modules/dashboard/dashboard.module')).DashboardModule,
    canActivate: [AuthGuard, RestrictedModeGuard],
  },
  {
    path: 'software',
    loadChildren: async () => (await import('./modules/software/software.module')).SoftwareModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'saas-ops',
    loadChildren: async () => (await import('./modules/saas-ops/saas-ops.module')).SaasOpsModule,
    canActivate: [AuthGuard, RestrictedModeGuard],
  },
  {
    path: 'controllers',
    loadChildren: async () => (await import('./modules/controller/controller.module')).ControllerModule,
    canActivate: [AuthGuard, RestrictedModeGuard],
  },
  {
    path: 'notifications',
    loadChildren: async () => (await import('./modules/notifications/notifications.module')).NotificationsModule,
    canActivate: [AuthGuard, RestrictedModeGuard],
  },
  {
    path: 'inventory',
    loadChildren: async () => (await import('./modules/inventory/inventory.module')).InventoryModule,
    canActivate: [AuthGuard, RestrictedModeGuard],
  },
  {
    path: 'licensing',
    loadChildren: async () => (await import('./modules/licensing/licensing.module')).LicensingModule,
    canActivate: [AuthGuard, RestrictedModeGuard],
  },
  {
    path: 'error-page',
    component: ErrorPageComponent,
  },
  {
    /**
     * Handle all the software download requests coming from customer connect page.
     * TODO: Need to work on AV-171927 once change request is completed for customer connect page.
     */
    path: 'vantage-details/:id',
    redirectTo: 'software/nsx-alb/:id',
  },
  {
    path: 'patch-details/:id',
    redirectTo: 'software/nsx-alb/:id',
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        onSameUrlNavigation: 'reload',
        /**
         * For now we are using PreloadAllModules, but when application will have more modules
         * then we will need to implement customPreloadingStrategy for it.
         * 1. Eagerly Load the required modules at startup (AuthModule, CoreModule, SharedModule).
         * 2. Preload all frequently used modules, maybe after some delay.
         * 3. Lazy load remaining modules.
         */
        preloadingStrategy: PreloadAllModules,
      },
    ),
  ],
  exports: [RouterModule],
})
export class AcpRoutingModule {
  constructor(private readonly router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}
