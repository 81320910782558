<div class="modal">
  <div
    class="modal-dialog"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{ l10nKeys.sessionTimeoutWarningLabel | vtranslate }}</h3>
      </div>
      <div class="modal-body">
        {{ l10nKeys.sessionTimeoutExtendWarning | vtranslate : getSessionTimeoutMessage() }}
      </div>
      <div
        class="modal-footer" 
        cds-layout="horizontal gap:sm"
      >
        <cds-button 
          action="outline"
          (click)="logout()"
        >
          {{ l10nKeys.logoutLabel | vtranslate }}
        </cds-button>
        <cds-button (click)="continue()">
          {{ globalL10nKeys.continueLabel | vtranslate }}
        </cds-button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal-backdrop"
  aria-hidden="true"
></div>
