/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { LoadingIndicatorService } from '../../../shared/services/loading-indicator.service';

/**
 * @description
 *  This Component is used to show loader icon at the center of the page.
 * @author Amit Dubey, Ram Pal
 */
@Component({
  selector: 'acp-page-loader',
  templateUrl: './acp-page-loader.component.html',
  styleUrls: ['./acp-page-loader.component.less'],
})
export class AcpPageLoaderComponent implements OnInit, OnDestroy {
  public isLoading = false;
  private loadingSubscription: Subscription = new Subscription();

  constructor(public loadingIndicatorService: LoadingIndicatorService) {}

  public ngOnInit(): void {
    this.loadingSubscription = this.loadingIndicatorService.isLoading$.subscribe(loadingStatus => {
      this.isLoading = loadingStatus;
    });
  }

  public ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
