/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AcpModule */

import { Injectable } from '@angular/core';

import {
  CanActivate,
  Router,
} from '@angular/router';

import { ROUTER_LINKS } from './modules/shared/router-links.constants';
import { AuthService } from './modules/shared/services/auth.service';

/**
 * @description Restricted Mode Guard to provide a check for entitlements and orgs.
 * Re-direct users to software page in case entitlements and orgs are not assigned.
 *
 * @author Abhinesh Gour
 */

@Injectable()
export class RestrictedModeGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  public canActivate(): boolean {
    if (this.authService.isRestrictedMode()) {
      this.redirectToSoftware();

      return false;
    }

    return true;
  }

  /**
   * Redirects user to Software in case of Restricted Mode.
   * As UI allows access to only Software Page in case user is in Restricted Mode.
   */
  private redirectToSoftware(): void {
    this.router.navigateByUrl(ROUTER_LINKS.SOFTWARE_HOME);
  }
}
